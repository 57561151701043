.c-section-ttl {
  margin: 40px 0;
  text-align: center;
  h2 {
    @include f-KaiseiOpti;
    line-height: 1;
    @include pc(&) {
      font-size: 34px;
    }
    @include sp(&) {
      font-size: 30px;
    }
  }
  .sub {
    @include f-YesevaOne;
    margin-top: 10px;
    line-height: 1;
    color: $c-green;
    @include pc(&) {
      font-size: 20px;
    }
    @include sp(&) {
      font-size: 16px;
    }
  }
}

.c-more-btn {
  @include pc(&) {
    margin: 50px 0;
  }
  @include sp(&) {
    margin: 25px 0;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-weight: bold;
    color: $c-white;
    background: $c-green;
    border-radius: 100vmax;
    @include pc(&) {
      gap: 12px;
      width: 300px;
      height: 60px;
      font-size: 22px;
    }
    @include sp(&) {
      gap: 14px;
      width: 280px;
      height: 50px;
      font-size: 18px;
    }
    &:before {
      position: relative;
      width: 20px;
      height: 10px;
      content: '';
      background-image: url('/wwwroot/design/img/common/icn_arrow_more.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    &:hover {
      @include pc(&) {
        color: $c-green;
        background: $c-white;
        border: 2px solid $c-green;
        &:before {
          background-image: url('/wwwroot/design/img/common/icn_arrow_more_green.png');
        }
      }
    }
  }
}

.c-navitopicpath {
  display: flex;
  align-items: center;
  margin: 0 auto;
  @include pc(&) {
    gap: 10px;
    width: 1080px;
    padding: 0 0 10px 0;
    font-size: 14px;
  }
  @include sp(&) {
    gap: 8px;
    padding: 0 20px;
    margin: 20px 0;
    font-size: 12px;
    letter-spacing: -0.02em;
  }
}
