// color
$c-white: #fff;
$c-gray: #d8d4dd;
$c-gray2: #d8d4dd;
$c-gray3: #f5f4f2;
$c-gray4: #959595;
$c-gray5: #707070;
$c-gray6: #eeeeee;
$c-gray7: #5b5c5a;
$c-gray8: #e5e1d9;
$c-black: #20211e;

$c-green: #5c6436;
$c-green2: #88915a;
$c-green3: #e3e8d0;
$c-green4: #bdcb82;
$c-orange: #f06544;
$c-orange2: #ef3f15;

// font
@import url('https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;700&family=Yeseva+One&family=Zen+Kaku+Gothic+New:wght@400;700&display=swap');
@mixin f-body {
  // font-family: 'Zen Kaku Gothic New', '游ゴシック体', 'YuGothic', '游ゴシック Medium', 'Yu Gothic Medium', 游ゴシック, 'Yu Gothic', 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Arial', 'Meiryo', sans-serif;
  font-family: 'Zen Kaku Gothic New', 'YuGothic', 'Yu Gothic Medium', 'Yu Gothic', 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Arial', 'Meiryo', sans-serif;
}
@mixin f-yugo {
  font-family: 'Yu Gothic', 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', 'Arial', 'Meiryo', sans-serif;
}
@mixin f-hira {
  font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Noto Sans JP', 'Helvetica Neue', Arial, 'Roboto', Meiryo, sans-serif;
}
@mixin f-KaiseiOpti {
  font-family: 'Kaisei Opti', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Noto Sans JP', 'Helvetica Neue', Arial, 'Roboto', Meiryo, sans-serif;
}
@mixin f-YesevaOne {
  font-family: 'Yeseva One', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Noto Sans JP', 'Helvetica Neue', Arial, 'Roboto', Meiryo, sans-serif;
}
@mixin f-ZenKakuGothicNew {
  font-family: 'Zen Kaku Gothic New', 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', 'Noto Sans JP', 'Helvetica Neue', Arial, 'Roboto', Meiryo, sans-serif;
}
@mixin f-QuincyCF {
  font-family: quincy-cf, serif !important;
}
