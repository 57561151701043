.c-header {
  background: $c-white;
  @include pc(&) {
    margin-bottom: 30px;
  }
  &-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $c-green2;
    @include pc(&) {
      height: 40px;
    }
    @include sp(&) {
      height: 30px;
    }
    &-inr {
      display: flex;
      justify-content: center;
    }
    &-splide {
      .header-slider {
        @include pc(&) {
          width: 600px;
        }
        @include sp(&) {
          width: 85%;
          margin: 0 auto;
        }
        .splide__list {
          .cont {
            padding: 0 10px;
            text-align: center;
            background: $c-white;
            border-radius: 100vmax;
            .txt {
              font-size: 16px;
              font-weight: bold;
              color: $c-green;
              span {
                color: $c-orange2;
              }
            }
          }
        }
        .splide__arrows {
          position: absolute;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
          .splide__arrow {
            position: absolute;
            top: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 10px;
            background: none;
            transform: translateY(-50%);
            img {
              height: 10px;
            }
            &--prev {
              left: -10px;
            }
            &--next {
              right: -10px;
            }
          }
        }
      }
    }
  }
  &-nav {
    padding: 5px 0;
    margin: 0 auto;
    @include pc(&) {
      width: 1080px;
    }
    @include sp(&) {
      display: none;
    }
    &-inr {
      display: flex;
      justify-content: space-between;
      height: 20px;
    }
    &-left {
    }
    &-right {
      display: flex;
      gap: 20px;
      a {
        display: flex;
        gap: 5px;
        align-items: center;
        img {
          height: 8px;
        }
      }
    }
  }
  &-main {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    &-inr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 1080px;
      height: 100%;
      margin: 0 auto;
    }
    &-left {
      display: flex;
      gap: 40px;
      align-items: center;
      height: 100%;
      a {
        font-size: 16px;
        font-weight: bold;
        .box {
          img {
            height: 35px;
          }
        }
      }
      .js-search-open {
        height: 100%;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        .link {
          display: flex;
          gap: 10px;
          align-items: center;
          height: 100%;
          img {
            height: 8px;
            transition: all ease 0.3s;
          }
        }
        .searchbox {
          position: absolute;
          top: 80px;
          left: 0;
          z-index: 99;
          width: 100vw;
          padding: 20px 0;
          pointer-events: none;
          background: $c-white;
          opacity: 0;
          transition: all ease 0.3s;
        }
        &:hover {
          opacity: 1;
          .link {
            img {
              transform: rotateX(180deg);
            }
          }
        }
      }
    }
    &-right {
      display: flex;
      gap: 30px;
      a {
        display: block;
        .box {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          img {
            height: 23px;
          }
          .txt {
            font-size: 12px;
            font-weight: bold;
          }
        }
      }
    }
    &-search {
      width: 180px;
      height: 40px;
      .search_form_ {
        display: flex;
        height: 100%;
        border: 1px solid $c-gray;
        border-radius: 100vmax;
      }
      &__submit {
        button {
          box-sizing: content-box;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-left: 8px;
          cursor: pointer;
          background: none;
          border: none;
          img {
            height: 21px;
          }
        }
      }
      &__input {
        flex: 1;
        input {
          width: 100%;
          height: 100%;
          padding: 0 12px;
          font-size: 12px;
          color: $c-black;
          border: none;
        }
      }
    }
  }
  &-main--sp {
    &-inr {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      height: 60px;
      padding: 0 15px;
    }
    &-left {
      display: flex;
      gap: 17px;
      align-items: center;
      button.js-hbg-open {
        img {
          height: 18px;
        }
      }
      button.js-hbgSearch-open {
        img {
          height: 20px;
        }
      }
      a {
        img {
          height: 20px;
        }
      }
    }
    &-center {
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        a {
          img {
            height: 24px;
          }
        }
      }
    }
    &-right {
      display: flex;
      gap: 15px;
      align-items: center;
      justify-content: flex-end;
      a {
        img {
          height: 20px;
        }
      }
    }
  }
  &-info {
    width: 100%;
    background: $c-orange;
    &-inr {
      display: flex;
      align-items: center;
      margin: 0 auto;
      @include pc(&) {
        gap: 20px;
        width: 1080px;
        height: 50px;
        padding: 10px 0;
      }
      @include sp(&) {
        gap: 10px;
        width: 100%;
        height: 40px;
        padding: 10px 5px;
      }
      .label {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        font-weight: bold;
        line-height: 1;
        color: $c-orange2;
        text-overflow: ellipsis;
        white-space: nowrap;
        background: $c-white;
        border-radius: 5px;
        @include pc(&) {
          padding: 8px 11px;
          font-size: 14px;
        }
        @include sp(&) {
          padding: 8px 11px;
          font-size: 12px;
        }
      }
      .link {
        width: calc(100% - 130px);
        a {
          display: flex;
          gap: 8px;
          align-items: center;
          width: 100%;
          overflow: hidden;
          color: $c-white;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include pc(&) {
            font-size: 14px;
          }
          @include sp(&) {
            font-size: 13px;
          }
          img {
            height: 8px;
          }
        }
      }
    }
  }
}

// pc search box
body.is-search-open {
  .js-search-open {
    .searchbox {
      pointer-events: all;
      opacity: 1;
    }
  }
}

.c-hbg,
.c-hbgSearch,
.c-hbgLow-1,
.c-hbgLow-2,
.c-hbgLow-3,
.c-hbgLow-4 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100svh;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  background: $c-white;
  transform: translateX(-100%);
  &.is-transition-on {
    transition: all ease 0.3s;
  }
  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
    width: 40px;
    height: 40px;
    padding: 10px;
    button.js-hbg-close,
    button.js-hbgSearch-close {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &-inr {
    width: 100%;
  }
  &.is-open {
    transform: translateX(0);
  }
}

.c-hbg {
  z-index: 99;
  &-header {
    padding: 20px;
    .link {
      display: flex;
      align-items: center;
      a {
        font-size: 14px;
        font-weight: bold;
      }
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
    .welcome {
      margin-top: 20px;
      font-size: 16px;
    }
    .btns {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      margin-top: 20px;
      a.btn {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        height: 50px;
        font-size: 16px;
        font-weight: bold;
        color: $c-green;
        background: $c-white;
        border: 1px solid $c-green;
        border-radius: 100vmax;
        &:last-child {
          color: $c-white;
          background: $c-green;
          border: 1px solid $c-white;
        }
      }
    }
  }
  &-main {
    width: 100%;
  }
  &-footer {
    width: 100%;
    margin: 30px 0 63px;
    .sns {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      a {
        width: 36px;
        height: 36px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.c-hbgLow-1,
.c-hbgLow-2,
.c-hbgLow-3,
.c-hbgLow-4 {
  z-index: 999;
  transform: translateX(100%);
  &-prev {
    button.js-hbgLow-1-prev,
    button.js-hbgLow-2-prev,
    button.js-hbgLow-3-prev,
    button.js-hbgLow-4-prev {
      display: flex;
      gap: 15px;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 0 20px;
      color: $c-black;
      img {
        height: 11px;
      }
      .txt {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

// need body to ovverride defaoult style
body {
  .c-hbgSearch {
    top: 89px;
    z-index: 9;
    height: calc(100svh - 89px);
    &-search {
      width: 100%;
      height: 100px;
      padding: 50px 20px 0;
      .search_form_ {
        display: flex;
        float: none;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        background: $c-gray3;
        border-radius: 100vmax;
      }
      &__submit {
        button {
          box-sizing: content-box;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          margin-right: 20px;
          cursor: pointer;
          background: none;
          border: none;
          img {
            height: 20px;
          }
        }
      }
      &__input {
        flex: 1;
        input.keyword_ {
          width: 100%;
          height: 100%;
          min-height: 0;
          padding: 0 20px;
          margin: 0;
          font-size: 16px;
          color: $c-black;
          text-indent: 0;
          background: none;
          border: none;
        }
      }
    }
    &-keywords {
      padding-top: 40px;
      .ttl {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
      .main {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        padding: 0 20px;
        margin-top: 20px;
        .keyword {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          font-size: 13px;
          border: 1px solid $c-green;
          border-radius: 100vmax;
        }
      }
    }
    &-main {
      padding: 40px 0 60px;
    }
  }
}

body.is-fixed {
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

html.is-fixed {
  overflow-y: hidden;
}
