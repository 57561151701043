// pc common block search
.block-search {
  width: 1080px;
  height: 560px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
  .tabs {
    display: flex;
    width: 100%;
    .tab {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
      background: $c-green3;
      &:nth-child(odd) {
        background: $c-gray3;
      }
      &.is-active {
        color: $c-white;
        background: $c-green;
        &:after {
          position: absolute;
          bottom: -12px;
          left: 50%;
          z-index: 9;
          width: 0;
          height: 0;
          content: '';
          border-color: $c-green transparent transparent transparent;
          border-style: solid;
          border-width: 13px 14px 0 14px;
          transform: translateX(-50%);
        }
      }
      &:hover {
        z-index: 9;
        opacity: 0.5;
      }
    }
  }
  .conts {
    position: relative;
    width: 100%;
    height: 480px;
    background: $c-white;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    .cont {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      grid-template-rows: repeat(10, 1fr);
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: column;
      gap: 18px;
      width: 100%;
      height: 100%;
      padding: 60px 50px 70px;
      &.is-active {
        display: grid;
      }
    }
    .cont-1,
    .cont-4,
    .cont-5 {
      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        &::before {
          position: relative;
          display: inline-block;
          flex-shrink: 0;
          width: 5px;
          height: 100%;
          margin-right: 15px;
          content: '';
          background-image: url('/wwwroot/design/img/common/arrow_next_green.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
        &:hover {
          color: $c-green;
        }
      }
    }
    .cont-2 {
      &.is-active {
        display: block;
      }
      .list {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(7, 1fr);
        grid-auto-flow: row;
        gap: 18px 20px;
        a {
          display: flex;
          flex-direction: column;
          gap: 10px;
          align-items: center;
          .img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            aspect-ratio: 1/1;
            border: 1px solid $c-green3;
            border-radius: 100vmax;
            img {
              width: 90%;
            }
          }
          .txt {
            font-size: 16px;
            font-weight: bold;
            line-height: 1;
            text-align: center;
          }
          &:hover {
            .txt {
              color: $c-green;
            }
          }
        }
      }
      .more {
        margin-top: 30px;
        a {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 40px;
          }
          .txt {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
    .cont-3 {
      &.is-active {
        display: block;
      }
      .keywords {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        a.keyword {
          padding: 12px 30px;
          font-weight: bold;
          line-height: 1;
          background: $c-white;
          border: 1px solid $c-green;
          border-radius: 100vmax;
          &:hover {
            color: $c-white;
            background: $c-green;
            opacity: 1;
          }
        }
      }
    }
  }
}
