@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti:wght@400;700&family=Yeseva+One&family=Zen+Kaku+Gothic+New:wght@400;700&display=swap");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* smartpn.css override */
[data-browse-mode=S] html {
  font-size: 14px;
}

[data-browse-mode=S] body {
  font-size: 14px;
}

[data-browse-mode=S] div {
  font-size: 14px;
}

[data-browse-mode=S] span {
  font-size: 14px;
}

[data-browse-mode=S] object {
  font-size: 14px;
}

[data-browse-mode=S] iframe {
  font-size: 14px;
}

[data-browse-mode=S] h1 {
  font-size: 14px;
}

[data-browse-mode=S] h2 {
  font-size: 14px;
}

[data-browse-mode=S] h3 {
  font-size: 14px;
}

[data-browse-mode=S] h4 {
  font-size: 14px;
}

[data-browse-mode=S] h5 {
  font-size: 14px;
}

[data-browse-mode=S] h6 {
  font-size: 14px;
}

[data-browse-mode=S] p {
  font-size: 14px;
}

[data-browse-mode=S] blockquote {
  font-size: 14px;
}

[data-browse-mode=S] pre {
  font-size: 14px;
}

[data-browse-mode=S] abbr {
  font-size: 14px;
}

[data-browse-mode=S] address {
  font-size: 14px;
}

[data-browse-mode=S] cite {
  font-size: 14px;
}

[data-browse-mode=S] code {
  font-size: 14px;
}

[data-browse-mode=S] del {
  font-size: 14px;
}

[data-browse-mode=S] dfn {
  font-size: 14px;
}

[data-browse-mode=S] em {
  font-size: 14px;
}

[data-browse-mode=S] img {
  font-size: 14px;
}

[data-browse-mode=S] ins {
  font-size: 14px;
}

[data-browse-mode=S] kbd {
  font-size: 14px;
}

[data-browse-mode=S] q {
  font-size: 14px;
}

[data-browse-mode=S] samp {
  font-size: 14px;
}

[data-browse-mode=S] small {
  font-size: 14px;
}

[data-browse-mode=S] strong {
  font-size: 14px;
}

[data-browse-mode=S] sub {
  font-size: 14px;
}

[data-browse-mode=S] sup {
  font-size: 14px;
}

[data-browse-mode=S] var {
  font-size: 14px;
}

[data-browse-mode=S] b {
  font-size: 14px;
}

[data-browse-mode=S] i {
  font-size: 14px;
}

[data-browse-mode=S] dl {
  font-size: 14px;
}

[data-browse-mode=S] dt {
  font-size: 14px;
}

[data-browse-mode=S] dd {
  font-size: 14px;
}

[data-browse-mode=S] ol {
  font-size: 14px;
}

[data-browse-mode=S] ul {
  font-size: 14px;
}

[data-browse-mode=S] li {
  font-size: 14px;
}

[data-browse-mode=S] fieldset {
  font-size: 14px;
}

[data-browse-mode=S] form {
  font-size: 14px;
}

[data-browse-mode=S] label {
  font-size: 14px;
}

[data-browse-mode=S] legend {
  font-size: 14px;
}

[data-browse-mode=S] table {
  font-size: 14px;
}

[data-browse-mode=S] caption {
  font-size: 14px;
}

[data-browse-mode=S] tbody {
  font-size: 14px;
}

[data-browse-mode=S] tfoot {
  font-size: 14px;
}

[data-browse-mode=S] thead {
  font-size: 14px;
}

[data-browse-mode=S] tr {
  font-size: 14px;
}

[data-browse-mode=S] th {
  font-size: 14px;
}

[data-browse-mode=S] td {
  font-size: 14px;
}

[data-browse-mode=S] article {
  font-size: 14px;
}

[data-browse-mode=S] aside {
  font-size: 14px;
}

[data-browse-mode=S] canvas {
  font-size: 14px;
}

[data-browse-mode=S] details {
  font-size: 14px;
}

[data-browse-mode=S] figcaption {
  font-size: 14px;
}

[data-browse-mode=S] figure {
  font-size: 14px;
}

[data-browse-mode=S] footer {
  font-size: 14px;
}

[data-browse-mode=S] header {
  font-size: 14px;
}

[data-browse-mode=S] hgroup {
  font-size: 14px;
}

[data-browse-mode=S] menu {
  font-size: 14px;
}

[data-browse-mode=S] nav {
  font-size: 14px;
}

[data-browse-mode=S] section {
  font-size: 14px;
}

[data-browse-mode=S] summary {
  font-size: 14px;
}

[data-browse-mode=S] time {
  font-size: 14px;
}

[data-browse-mode=S] mark {
  font-size: 14px;
}

[data-browse-mode=S] audio {
  font-size: 14px;
}

[data-browse-mode=S] video {
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
  overflow-x: auto;
}

:root {
  scroll-padding: 30px;
}

body {
  font-family: "Zen Kaku Gothic New", "YuGothic", "Yu Gothic Medium", "Yu Gothic", "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Meiryo", sans-serif;
  position: relative;
  font-size: 14px;
  line-height: normal;
  color: #20211e;
  letter-spacing: 0.03em;
  word-break: break-all;
}
[data-browse-mode=P] body {
  min-width: 1120px;
}

[data-browse-mode=S] body {
  width: 100%;
  min-width: auto;
}
[data-browse-mode=S] body::-webkit-scrollbar {
  display: none;
}

div.wrapper_ {
  overflow-x: hidden;
  overflow-y: clip;
  background: url("/wwwroot/design/img/common/bg_1.png") repeat left top;
}
div.wrapper_ .container_ {
  padding: 0;
}
div.wrapper_ .container_ .contents_ {
  width: auto;
}
div.wrapper_ .container_ .contents_ .mainframe_ {
  float: none;
  width: auto;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.05em;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}
a:hover,
a:link:hover,
a:visited:hover {
  opacity: 0.6;
}

p {
  line-height: 1.6;
}
[data-browse-mode=S] p {
  letter-spacing: normal;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

button {
  display: block;
  cursor: pointer;
  background: none;
  border: none;
}

select,
select * {
  box-sizing: border-box;
  min-width: 1.4em;
  min-height: 1.4em;
  padding: 0;
  padding: 0.2em 0.5em;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  vertical-align: middle;
  background: #fff;
  background-color: transparent;
  border: 0;
  border: 1px solid #6d453b;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

*:focus-visible {
  outline: none;
}

[data-browse-mode=S] .u-pc {
  display: none !important;
}

[data-browse-mode=P] .u-sp {
  display: none !important;
}

.u-input,
.u-button,
.u-select,
.u-textarea {
  font: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.u-button,
.u-select,
.u-textarea {
  cursor: pointer;
}

.review {
  height: 35px;
}
.review .revico-star .revico-star-block--rate {
  top: 2px;
  margin: 0;
}
[data-browse-mode=P] .review .revico-star .revico-star-block--rate {
  transform: scale(0.5);
}

[data-browse-mode=S] .review .revico-star .revico-star-block--rate {
  transform: scale(0.45);
}

[data-browse-mode=P] .review .revico-star .revico-star-block--title {
  margin-left: -70px;
  font-size: 13px;
}

[data-browse-mode=S] .review .revico-star .revico-star-block--title {
  margin-left: -77px;
  font-size: 12px;
}

.review .revico-star .revico-star-block--total {
  margin: 0;
}
[data-browse-mode=P] .review .revico-star .revico-star-block--total {
  font-size: 11px;
}

[data-browse-mode=S] .review .revico-star .revico-star-block--total {
  margin-top: 1px;
  font-size: 10px;
}

.m_info {
  overflow: hidden;
}
.m_info img {
  display: inline;
  float: left;
  margin: 0 10px 10px 0;
}
.m_info p.bottan a {
  overflow: hidden;
}
.m_info p.bottan img {
  float: right;
  margin: 0;
  text-align: right;
}

.div.goodscomment_ .goodscomment_head {
  padding: 0;
  background: none;
  border: none;
}

.goodscomment_head {
  padding: 0;
  background: none;
  border: none;
}

table.keepcart_ td {
  vertical-align: bottom;
}

[data-browse-mode=P] .block-staff {
  width: 1000px;
  margin: 0 auto;
}

[data-browse-mode=S] .block-staff {
  padding: 0 20px;
}

.rightmenuframe_ {
  display: none;
}

[data-browse-mode=S] .freepage_ {
  margin-top: 20px;
}

h1.category_name_ {
  background: none;
}

h1.event_name_ {
  background: none;
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
}
[data-browse-mode=P] h1.event_name_ {
  min-height: 0;
  padding: 0;
  margin: 0;
  font-size: 28px;
  font-weight: normal;
  line-height: normal;
  color: #20211e;
}

[data-browse-mode=S] h1.event_name_ {
  padding: 0 20px;
  font-size: 22px;
}

[data-browse-mode=S] .shop_detail_ {
  padding: 0 20px;
}

[data-browse-mode=S] #company.guidepage_ h1.common_headline1_ {
  padding: 0 20px;
}
[data-browse-mode=S] #company.guidepage_ .style15_ {
  padding: 0 20px;
}
[data-browse-mode=S] #company.guidepage_ .style15_ .box_ {
  padding: 0;
}

.goodscomment6_ {
  margin-top: 50px;
  font-size: 16px;
  line-height: 1.6;
}

[data-browse-mode=S] .order_ h1.common_headline1_ {
  margin-right: 10px;
  margin-left: 10px;
}

[data-browse-mode=S] .order_ h2.common_headline2_ {
  margin-right: 10px;
  margin-left: 10px;
}

[data-browse-mode=S] .order_ .method_host_ h2.common_headline2_ {
  margin-top: 0;
}

[data-browse-mode=S] .customer_ h1.common_headline1_ {
  margin-left: 10px;
}

[data-browse-mode=S] .customer_ h2 {
  margin-left: 10px;
}

[data-browse-mode=S] .page-list .goods_ {
  padding: 0 20px;
}

[data-browse-mode=S] input[name=min_price] {
  width: 90%;
}

[data-browse-mode=S] input[name=max_price] {
  width: 90%;
}

[data-browse-mode=P] div.bookmark_item_ div.cartgoods_ {
  padding: 0;
  margin-right: 10px;
}

[data-browse-mode=P] div.bookmark_item_ div.del_ {
  padding: 0;
}

.c-section-ttl {
  margin: 40px 0;
  text-align: center;
}
.c-section-ttl h2 {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  line-height: 1;
}
[data-browse-mode=P] .c-section-ttl h2 {
  font-size: 34px;
}

[data-browse-mode=S] .c-section-ttl h2 {
  font-size: 30px;
}

.c-section-ttl .sub {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  margin-top: 10px;
  line-height: 1;
  color: #5c6436;
}
[data-browse-mode=P] .c-section-ttl .sub {
  font-size: 20px;
}

[data-browse-mode=S] .c-section-ttl .sub {
  font-size: 16px;
}

[data-browse-mode=P] .c-more-btn {
  margin: 50px 0;
}

[data-browse-mode=S] .c-more-btn {
  margin: 25px 0;
}

.c-more-btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-weight: bold;
  color: #fff;
  background: #5c6436;
  border-radius: 100vmax;
}
[data-browse-mode=P] .c-more-btn a {
  gap: 12px;
  width: 300px;
  height: 60px;
  font-size: 22px;
}

[data-browse-mode=S] .c-more-btn a {
  gap: 14px;
  width: 280px;
  height: 50px;
  font-size: 18px;
}

.c-more-btn a:before {
  position: relative;
  width: 20px;
  height: 10px;
  content: "";
  background-image: url("/wwwroot/design/img/common/icn_arrow_more.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
[data-browse-mode=P] .c-more-btn a:hover {
  color: #5c6436;
  background: #fff;
  border: 2px solid #5c6436;
}
[data-browse-mode=P] .c-more-btn a:hover:before {
  background-image: url("/wwwroot/design/img/common/icn_arrow_more_green.png");
}

.c-navitopicpath {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
[data-browse-mode=P] .c-navitopicpath {
  gap: 10px;
  width: 1080px;
  padding: 0 0 10px 0;
  font-size: 14px;
}

[data-browse-mode=S] .c-navitopicpath {
  gap: 8px;
  padding: 0 20px;
  margin: 20px 0;
  font-size: 12px;
  letter-spacing: -0.02em;
}

/**************************\
  Basic Modal Styles
\**************************/
.body-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}

.modal__container {
  position: relative;
  box-sizing: border-box;
  max-height: 80%;
  padding: 40px 30px;
  overflow-y: auto;
  background-color: #fff;
}
[data-browse-mode=P] .modal__container {
  width: 700px;
  border-radius: 20px;
}

[data-browse-mode=S] .modal__container {
  width: calc(100% - 40px);
  border-radius: 14px;
}

.modal__header {
  padding-bottom: 23px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid #e3e8d0;
}

.modal__content {
  margin-top: 20px;
}
.modal__content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #5c6436;
}
[data-browse-mode=P] .modal__content h4 {
  margin-top: 30px;
}

[data-browse-mode=S] .modal__content h4 {
  margin-top: 40px;
}

.modal__content .modal__boxlist {
  gap: 10px;
  margin-top: 20px;
}
[data-browse-mode=P] .modal__content .modal__boxlist {
  display: flex;
  justify-content: space-between;
}

[data-browse-mode=S] .modal__content .modal__boxlist {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.modal__content .modal__boxlist .box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #5c6436;
  border: 1px solid #5c6436;
  border-radius: 4px;
}
.modal__content .modal__cardbrand {
  margin-top: 20px;
}
.modal__content .modal__cardbrand img {
  height: 43px;
}
.modal__content .modal__atobarai {
  margin-top: 12px;
}
[data-browse-mode=S] .modal__content .modal__atobarai {
  display: flex;
  justify-content: center;
}

.modal__content .modal__atobarai img {
  height: 44px;
}
.modal__content .modal__note {
  width: 100%;
  padding: 10px 0;
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  background: #e3e8d0;
}
[data-browse-mode=P] .modal__content .modal__note {
  height: 40px;
}

[data-browse-mode=S] .modal__content .modal__note {
  line-height: 1.2;
}

.modal__content .modal__note span {
  color: #ef3f15;
}
.modal__content .modal_timebox {
  display: flex;
  margin-top: 20px;
}
.modal__content .modal_timebox .box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #5c6436;
  text-align: center;
  border-color: #5c6436;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
}
[data-browse-mode=P] .modal__content .modal_timebox .box {
  width: 80px;
  height: 80px;
}

[data-browse-mode=S] .modal__content .modal_timebox .box {
  width: 50px;
  height: 50px;
}

.modal__content .modal_timebox .box:last-child {
  border-width: 1px;
}
.modal__content .modal_iconGrid {
  margin-top: 24px;
}
[data-browse-mode=P] .modal__content .modal_iconGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 13px;
}

[data-browse-mode=S] .modal__content .modal_iconGrid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal__content .modal_iconGrid img {
  height: 28px;
}
.modal__content .modal_iconGrid .box {
  display: flex;
  gap: 20px;
}
.modal__content .modal_iconGrid .box .txt {
  font-size: 14px;
}
.modal__content .modal_iconGrid .box.l-2 {
  display: block;
}
.modal__content .modal_iconGrid .box.l-2 .flex {
  display: flex;
  gap: 5px;
  align-items: center;
}
.modal__content .modal_iconGrid .box.l-2 .flex:nth-child(2) {
  margin-top: 5px;
}
.modal__content .modal_iconGrid .box.l-2 .flex img {
  display: inline-block;
}
.modal__content .modal_iconGrid .box.l-2 .flex .txt {
  display: inline-block;
  margin-left: 10px;
}
[data-browse-mode=P] .modal__content .modal_alignCenter {
  display: flex;
  gap: 5px;
  align-items: center;
}

.modal__content .modal_alignCenter img {
  height: 28px;
}
[data-browse-mode=S] .modal__content .modal_alignCenter img {
  display: inline-block;
  margin-top: 20px;
  margin-right: 5px;
}

[data-browse-mode=P] .modal__content .modal_alignCenter .txt {
  margin-top: 4px;
  margin-left: 15px;
}

[data-browse-mode=S] .modal__content .modal_alignCenter .txt {
  margin-top: 20px;
}

.modal__content > p {
  margin-top: 20px;
}
[data-browse-mode=S] .modal__content > p {
  font-size: 15px;
}

.modal__content > a {
  display: block;
  margin-top: 20px;
  color: #5c6436;
  text-decoration: underline;
}
.modal__content > .modal__more {
  display: block;
  margin: 30px auto 0;
  font-size: 14px;
  line-height: 50px;
  color: #5c6436;
  text-align: center;
  text-decoration: none;
  border: solid 2px #5c6436;
  border-radius: 35px;
}
[data-browse-mode=P] .modal__content > .modal__more {
  width: 180px;
}

[data-browse-mode=S] .modal__content > .modal__more {
  width: 170px;
}

.modal_close {
  position: absolute;
}
[data-browse-mode=P] .modal_close {
  width: 650px;
  margin: -20px 0 0 auto;
}

[data-browse-mode=S] .modal_close {
  width: calc(100vw - 80px);
  margin: -30px 0 0 auto;
}

.modal_close button {
  width: 20px;
  height: 20px;
  margin: 0 0 0 auto;
}
.modal_close button img {
  width: 100%;
}

.modal__important {
  position: relative;
  max-width: 400px;
  margin: 0 auto 25px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: #f06544;
  border-radius: 30px;
}
.modal__important:before {
  position: absolute;
  top: 50%;
  width: 22px;
  height: 22px;
  content: "";
  background-image: url("/wwwroot/design/img/common/attend.png");
  background-position: center;
  background-size: cover;
  transform: translateY(-50%);
}
[data-browse-mode=P] .modal__important {
  padding-left: 42px;
  font-size: 18px;
}
[data-browse-mode=P] .modal__important:before {
  margin-left: -42px;
}

[data-browse-mode=S] .modal__important {
  padding-left: 30px;
  font-size: 16px;
}
[data-browse-mode=S] .modal__important:before {
  margin-left: -30px;
}

.modal__emphasis {
  color: #5c6436;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.c-header {
  background: #fff;
}
[data-browse-mode=P] .c-header {
  margin-bottom: 30px;
}

.c-header-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #88915a;
}
[data-browse-mode=P] .c-header-slider {
  height: 40px;
}

[data-browse-mode=S] .c-header-slider {
  height: 30px;
}

.c-header-slider-inr {
  display: flex;
  justify-content: center;
}
[data-browse-mode=P] .c-header-slider-splide .header-slider {
  width: 600px;
}

[data-browse-mode=S] .c-header-slider-splide .header-slider {
  width: 85%;
  margin: 0 auto;
}

.c-header-slider-splide .header-slider .splide__list .cont {
  padding: 0 10px;
  text-align: center;
  background: #fff;
  border-radius: 100vmax;
}
.c-header-slider-splide .header-slider .splide__list .cont .txt {
  font-size: 16px;
  font-weight: bold;
  color: #5c6436;
}
.c-header-slider-splide .header-slider .splide__list .cont .txt span {
  color: #ef3f15;
}
.c-header-slider-splide .header-slider .splide__arrows {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.c-header-slider-splide .header-slider .splide__arrows .splide__arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 10px;
  background: none;
  transform: translateY(-50%);
}
.c-header-slider-splide .header-slider .splide__arrows .splide__arrow img {
  height: 10px;
}
.c-header-slider-splide .header-slider .splide__arrows .splide__arrow--prev {
  left: -10px;
}
.c-header-slider-splide .header-slider .splide__arrows .splide__arrow--next {
  right: -10px;
}
.c-header-nav {
  padding: 5px 0;
  margin: 0 auto;
}
[data-browse-mode=P] .c-header-nav {
  width: 1080px;
}

[data-browse-mode=S] .c-header-nav {
  display: none;
}

.c-header-nav-inr {
  display: flex;
  justify-content: space-between;
  height: 20px;
}
.c-header-nav-right {
  display: flex;
  gap: 20px;
}
.c-header-nav-right a {
  display: flex;
  gap: 5px;
  align-items: center;
}
.c-header-nav-right a img {
  height: 8px;
}
.c-header-main {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
}
.c-header-main-inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1080px;
  height: 100%;
  margin: 0 auto;
}
.c-header-main-left {
  display: flex;
  gap: 40px;
  align-items: center;
  height: 100%;
}
.c-header-main-left a {
  font-size: 16px;
  font-weight: bold;
}
.c-header-main-left a .box img {
  height: 35px;
}
.c-header-main-left .js-search-open {
  height: 100%;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.c-header-main-left .js-search-open .link {
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
}
.c-header-main-left .js-search-open .link img {
  height: 8px;
  transition: all ease 0.3s;
}
.c-header-main-left .js-search-open .searchbox {
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 99;
  width: 100vw;
  padding: 20px 0;
  pointer-events: none;
  background: #fff;
  opacity: 0;
  transition: all ease 0.3s;
}
.c-header-main-left .js-search-open:hover {
  opacity: 1;
}
.c-header-main-left .js-search-open:hover .link img {
  transform: rotateX(180deg);
}
.c-header-main-right {
  display: flex;
  gap: 30px;
}
.c-header-main-right a {
  display: block;
}
.c-header-main-right a .box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}
.c-header-main-right a .box img {
  height: 23px;
}
.c-header-main-right a .box .txt {
  font-size: 12px;
  font-weight: bold;
}
.c-header-main-search {
  width: 180px;
  height: 40px;
}
.c-header-main-search .search_form_ {
  display: flex;
  height: 100%;
  border: 1px solid #d8d4dd;
  border-radius: 100vmax;
}
.c-header-main-search__submit button {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-left: 8px;
  cursor: pointer;
  background: none;
  border: none;
}
.c-header-main-search__submit button img {
  height: 21px;
}
.c-header-main-search__input {
  flex: 1;
}
.c-header-main-search__input input {
  width: 100%;
  height: 100%;
  padding: 0 12px;
  font-size: 12px;
  color: #20211e;
  border: none;
}
.c-header-main--sp-inr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 60px;
  padding: 0 15px;
}
.c-header-main--sp-left {
  display: flex;
  gap: 17px;
  align-items: center;
}
.c-header-main--sp-left button.js-hbg-open img {
  height: 18px;
}
.c-header-main--sp-left button.js-hbgSearch-open img {
  height: 20px;
}
.c-header-main--sp-left a img {
  height: 20px;
}
.c-header-main--sp-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-header-main--sp-center .logo a img {
  height: 24px;
}
.c-header-main--sp-right {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
}
.c-header-main--sp-right a img {
  height: 20px;
}
.c-header-info {
  width: 100%;
  background: #f06544;
}
.c-header-info-inr {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
[data-browse-mode=P] .c-header-info-inr {
  gap: 20px;
  width: 1080px;
  height: 50px;
  padding: 10px 0;
}

[data-browse-mode=S] .c-header-info-inr {
  gap: 10px;
  width: 100%;
  height: 40px;
  padding: 10px 5px;
}

.c-header-info-inr .label {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-weight: bold;
  line-height: 1;
  color: #ef3f15;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #fff;
  border-radius: 5px;
}
[data-browse-mode=P] .c-header-info-inr .label {
  padding: 8px 11px;
  font-size: 14px;
}

[data-browse-mode=S] .c-header-info-inr .label {
  padding: 8px 11px;
  font-size: 12px;
}

.c-header-info-inr .link {
  width: calc(100% - 130px);
}
.c-header-info-inr .link a {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
}
[data-browse-mode=P] .c-header-info-inr .link a {
  font-size: 14px;
}

[data-browse-mode=S] .c-header-info-inr .link a {
  font-size: 13px;
}

.c-header-info-inr .link a img {
  height: 8px;
}

body.is-search-open .js-search-open .searchbox {
  pointer-events: all;
  opacity: 1;
}

.c-hbg,
.c-hbgSearch,
.c-hbgLow-1,
.c-hbgLow-2,
.c-hbgLow-3,
.c-hbgLow-4 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100svh;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  background: #fff;
  transform: translateX(-100%);
}
.c-hbg.is-transition-on,
.c-hbgSearch.is-transition-on,
.c-hbgLow-1.is-transition-on,
.c-hbgLow-2.is-transition-on,
.c-hbgLow-3.is-transition-on,
.c-hbgLow-4.is-transition-on {
  transition: all ease 0.3s;
}
.c-hbg-close,
.c-hbgSearch-close,
.c-hbgLow-1-close,
.c-hbgLow-2-close,
.c-hbgLow-3-close,
.c-hbgLow-4-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.c-hbg-close button.js-hbg-close img,
.c-hbg-close button.js-hbgSearch-close img,
.c-hbgSearch-close button.js-hbg-close img,
.c-hbgSearch-close button.js-hbgSearch-close img,
.c-hbgLow-1-close button.js-hbg-close img,
.c-hbgLow-1-close button.js-hbgSearch-close img,
.c-hbgLow-2-close button.js-hbg-close img,
.c-hbgLow-2-close button.js-hbgSearch-close img,
.c-hbgLow-3-close button.js-hbg-close img,
.c-hbgLow-3-close button.js-hbgSearch-close img,
.c-hbgLow-4-close button.js-hbg-close img,
.c-hbgLow-4-close button.js-hbgSearch-close img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.c-hbg-inr,
.c-hbgSearch-inr,
.c-hbgLow-1-inr,
.c-hbgLow-2-inr,
.c-hbgLow-3-inr,
.c-hbgLow-4-inr {
  width: 100%;
}
.c-hbg.is-open,
.c-hbgSearch.is-open,
.c-hbgLow-1.is-open,
.c-hbgLow-2.is-open,
.c-hbgLow-3.is-open,
.c-hbgLow-4.is-open {
  transform: translateX(0);
}

.c-hbg {
  z-index: 99;
}
.c-hbg-header {
  padding: 20px;
}
.c-hbg-header .link {
  display: flex;
  align-items: center;
}
.c-hbg-header .link a {
  font-size: 14px;
  font-weight: bold;
}
.c-hbg-header .link img {
  height: 10px;
  margin-left: 5px;
}
.c-hbg-header .welcome {
  margin-top: 20px;
  font-size: 16px;
}
.c-hbg-header .btns {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 20px;
}
.c-hbg-header .btns a.btn {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #5c6436;
  background: #fff;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
.c-hbg-header .btns a.btn:last-child {
  color: #fff;
  background: #5c6436;
  border: 1px solid #fff;
}
.c-hbg-main {
  width: 100%;
}
.c-hbg-footer {
  width: 100%;
  margin: 30px 0 63px;
}
.c-hbg-footer .sns {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.c-hbg-footer .sns a {
  width: 36px;
  height: 36px;
}
.c-hbg-footer .sns a img {
  width: 100%;
}

.c-hbgLow-1,
.c-hbgLow-2,
.c-hbgLow-3,
.c-hbgLow-4 {
  z-index: 999;
  transform: translateX(100%);
}
.c-hbgLow-1-prev button.js-hbgLow-1-prev,
.c-hbgLow-1-prev button.js-hbgLow-2-prev,
.c-hbgLow-1-prev button.js-hbgLow-3-prev,
.c-hbgLow-1-prev button.js-hbgLow-4-prev,
.c-hbgLow-2-prev button.js-hbgLow-1-prev,
.c-hbgLow-2-prev button.js-hbgLow-2-prev,
.c-hbgLow-2-prev button.js-hbgLow-3-prev,
.c-hbgLow-2-prev button.js-hbgLow-4-prev,
.c-hbgLow-3-prev button.js-hbgLow-1-prev,
.c-hbgLow-3-prev button.js-hbgLow-2-prev,
.c-hbgLow-3-prev button.js-hbgLow-3-prev,
.c-hbgLow-3-prev button.js-hbgLow-4-prev,
.c-hbgLow-4-prev button.js-hbgLow-1-prev,
.c-hbgLow-4-prev button.js-hbgLow-2-prev,
.c-hbgLow-4-prev button.js-hbgLow-3-prev,
.c-hbgLow-4-prev button.js-hbgLow-4-prev {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  color: #20211e;
}
.c-hbgLow-1-prev button.js-hbgLow-1-prev img,
.c-hbgLow-1-prev button.js-hbgLow-2-prev img,
.c-hbgLow-1-prev button.js-hbgLow-3-prev img,
.c-hbgLow-1-prev button.js-hbgLow-4-prev img,
.c-hbgLow-2-prev button.js-hbgLow-1-prev img,
.c-hbgLow-2-prev button.js-hbgLow-2-prev img,
.c-hbgLow-2-prev button.js-hbgLow-3-prev img,
.c-hbgLow-2-prev button.js-hbgLow-4-prev img,
.c-hbgLow-3-prev button.js-hbgLow-1-prev img,
.c-hbgLow-3-prev button.js-hbgLow-2-prev img,
.c-hbgLow-3-prev button.js-hbgLow-3-prev img,
.c-hbgLow-3-prev button.js-hbgLow-4-prev img,
.c-hbgLow-4-prev button.js-hbgLow-1-prev img,
.c-hbgLow-4-prev button.js-hbgLow-2-prev img,
.c-hbgLow-4-prev button.js-hbgLow-3-prev img,
.c-hbgLow-4-prev button.js-hbgLow-4-prev img {
  height: 11px;
}
.c-hbgLow-1-prev button.js-hbgLow-1-prev .txt,
.c-hbgLow-1-prev button.js-hbgLow-2-prev .txt,
.c-hbgLow-1-prev button.js-hbgLow-3-prev .txt,
.c-hbgLow-1-prev button.js-hbgLow-4-prev .txt,
.c-hbgLow-2-prev button.js-hbgLow-1-prev .txt,
.c-hbgLow-2-prev button.js-hbgLow-2-prev .txt,
.c-hbgLow-2-prev button.js-hbgLow-3-prev .txt,
.c-hbgLow-2-prev button.js-hbgLow-4-prev .txt,
.c-hbgLow-3-prev button.js-hbgLow-1-prev .txt,
.c-hbgLow-3-prev button.js-hbgLow-2-prev .txt,
.c-hbgLow-3-prev button.js-hbgLow-3-prev .txt,
.c-hbgLow-3-prev button.js-hbgLow-4-prev .txt,
.c-hbgLow-4-prev button.js-hbgLow-1-prev .txt,
.c-hbgLow-4-prev button.js-hbgLow-2-prev .txt,
.c-hbgLow-4-prev button.js-hbgLow-3-prev .txt,
.c-hbgLow-4-prev button.js-hbgLow-4-prev .txt {
  font-size: 14px;
  font-weight: bold;
}

body .c-hbgSearch {
  top: 89px;
  z-index: 9;
  height: calc(100svh - 89px);
}
body .c-hbgSearch-search {
  width: 100%;
  height: 100px;
  padding: 50px 20px 0;
}
body .c-hbgSearch-search .search_form_ {
  display: flex;
  float: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #f5f4f2;
  border-radius: 100vmax;
}
body .c-hbgSearch-search__submit button {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 20px;
  cursor: pointer;
  background: none;
  border: none;
}
body .c-hbgSearch-search__submit button img {
  height: 20px;
}
body .c-hbgSearch-search__input {
  flex: 1;
}
body .c-hbgSearch-search__input input.keyword_ {
  width: 100%;
  height: 100%;
  min-height: 0;
  padding: 0 20px;
  margin: 0;
  font-size: 16px;
  color: #20211e;
  text-indent: 0;
  background: none;
  border: none;
}
body .c-hbgSearch-keywords {
  padding-top: 40px;
}
body .c-hbgSearch-keywords .ttl {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
body .c-hbgSearch-keywords .main {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
}
body .c-hbgSearch-keywords .main .keyword {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 13px;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
body .c-hbgSearch-main {
  padding: 40px 0 60px;
}

body.is-fixed {
  width: 100%;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

html.is-fixed {
  overflow-y: hidden;
}

#footer {
  height: auto;
  background: none;
}
#footer .change_mode_ {
  display: none;
}

.c-pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 92px;
  height: 183px;
  margin: 0 auto 20px;
  opacity: 0;
  transition: all ease 0.3s;
}
.c-pagetop a {
  display: block;
  width: 92px;
  height: 183px;
  margin: 0 50px 0 auto;
}
.c-pagetop a img {
  width: 100%;
  height: 100%;
}
[data-browse-mode=S] .c-pagetop a {
  right: 0;
  margin: 0 15px 0 auto;
}

.c-pagetop.is-show {
  opacity: 1;
}

.c-footer {
  background: #fff;
}
[data-browse-mode=P] .c-footer {
  margin-top: 50px;
}

[data-browse-mode=S] .c-footer {
  margin-top: 100px;
}

.c-footer a:hover {
  color: #5c6436;
}
.c-footer .c-acd-menu-box.is-open.lv2 {
  background: #f5f4f2;
  border-bottom: 2px solid #fff;
}
.c-footer .c-acd-menu-box.lv1 {
  background: #fff;
  border-bottom: 2px solid #f5f4f2;
}
.c-footer-main {
  margin: 0 auto;
}
[data-browse-mode=P] .c-footer-main {
  width: 1080px;
  padding-top: 50px;
}

[data-browse-mode=S] .c-footer-main {
  width: 100%;
}

.c-footer-main-inr {
  display: flex;
  justify-content: space-between;
}
[data-browse-mode=S] .c-footer-main-inr {
  flex-direction: column;
}

[data-browse-mode=P] .c-footer-main-left {
  display: flex;
  gap: 50px;
}
[data-browse-mode=P] .c-footer-main-left .box {
  display: flex;
  flex-direction: column;
}
[data-browse-mode=P] .c-footer-main-left .box .ttl {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}
[data-browse-mode=P] .c-footer-main-left .box .ttl:first-child {
  margin-top: 0;
}
[data-browse-mode=P] .c-footer-main-left .box .ttl img {
  height: 8px;
}
[data-browse-mode=P] .c-footer-main-left .box .link {
  margin-top: 10px;
  font-size: 14px;
}

[data-browse-mode=S] .c-footer-main-left {
  width: 100%;
}

[data-browse-mode=P] .c-footer-main-right {
  width: 400px;
}

[data-browse-mode=S] .c-footer-main-right {
  padding: 0 20px;
  margin-top: 50px;
}

.c-footer-main-right .top {
  display: none;
}
.c-footer-main-right .top a {
  font-weight: bold;
}
[data-browse-mode=P] .c-footer-main-right .top a {
  font-size: 16px;
}

[data-browse-mode=S] .c-footer-main-right .top a {
  font-size: 18px;
}

.c-footer-main-right .mid {
  display: none;
  gap: 20px;
}
[data-browse-mode=P] .c-footer-main-right .mid {
  margin-top: 30px;
}

[data-browse-mode=S] .c-footer-main-right .mid {
  flex-direction: column;
  margin-top: 20px;
}

[data-browse-mode=S] .c-footer-main-right .mid .box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.c-footer-main-right .mid .box img {
  display: block;
  height: 12px;
}
.c-footer-main-right .mid .box a {
  display: block;
  font-size: 12px;
  font-weight: bold;
}
[data-browse-mode=P] .c-footer-main-right .mid .box.mail {
  width: 180px;
}

.c-footer-main-right .mid .box.mail a {
  letter-spacing: 0.5px;
}
[data-browse-mode=P] .c-footer-main-right .mid .box.mail a {
  margin-top: 8px;
}

.c-footer-main-right .mid .box.tel a {
  font-size: 18px;
}
[data-browse-mode=P] .c-footer-main-right .mid .box.tel a {
  margin-top: 4px;
}

.c-footer-main-right .bot .ttl {
  font-size: 16px;
  font-weight: bold;
}
.c-footer-main-right .bot .date {
  margin-top: 14px;
  font-weight: bold;
}
[data-browse-mode=P] .c-footer-main-right .bot .date {
  font-size: 14px;
}

[data-browse-mode=S] .c-footer-main-right .bot .date {
  font-size: 16px;
  text-align: center;
}

.c-footer-main-right .bot .box {
  display: flex;
  gap: 20px;
  margin-top: 8px;
}
[data-browse-mode=S] .c-footer-main-right .bot .box {
  flex-direction: column;
}

.c-footer-main-right .bot .box table.calendar {
  border-spacing: 1px;
  border-collapse: initial;
  background: #e3e8d0;
}
[data-browse-mode=P] .c-footer-main-right .bot .box table.calendar {
  width: 180px;
}

[data-browse-mode=S] .c-footer-main-right .bot .box table.calendar {
  width: 250px;
  margin: 0 auto;
}

.c-footer-main-right .bot .box table.calendar tbody tr th {
  color: #fff;
  text-align: center;
  background: #5c6436;
}
[data-browse-mode=P] .c-footer-main-right .bot .box table.calendar tbody tr th {
  width: 26px;
  height: 26px;
}

[data-browse-mode=S] .c-footer-main-right .bot .box table.calendar tbody tr th {
  width: 36px;
  height: 36px;
}

.c-footer-main-right .bot .box table.calendar tbody tr td {
  text-align: center;
  background: #fff;
}
[data-browse-mode=P] .c-footer-main-right .bot .box table.calendar tbody tr td {
  width: 26px;
  height: 26px;
}

[data-browse-mode=S] .c-footer-main-right .bot .box table.calendar tbody tr td {
  width: 36px;
  height: 36px;
}

.c-footer-main-right .bot .box table.calendar tbody tr td.holiday {
  background: #d8d4dd;
}
.c-footer-main-right .bot .box .note {
  flex: 1;
  font-weight: bold;
}
[data-browse-mode=P] .c-footer-main-right .bot .box .note {
  font-size: 12px;
}

[data-browse-mode=S] .c-footer-main-right .bot .box .note {
  font-size: 14px;
}

.c-footer-main-right .bot .box .note .highlight {
  display: inline-block;
  width: 1em;
  height: 1em;
  background: #d8d4dd;
}
.c-footer-bot {
  margin-top: 50px;
}
.c-footer-bot-inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
[data-browse-mode=P] .c-footer-bot-inr {
  width: 1080px;
}

[data-browse-mode=S] .c-footer-bot-inr {
  flex-direction: column;
  width: 100%;
}

.c-footer-bot-inr .sns {
  display: flex;
  gap: 10px;
}
.c-footer-bot-inr .sns a img {
  width: 36px;
  height: 36px;
}
.c-footer-bot-inr .links {
  display: flex;
  align-items: center;
}
[data-browse-mode=P] .c-footer-bot-inr .links {
  gap: 30px;
}

[data-browse-mode=S] .c-footer-bot-inr .links {
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  width: 80%;
  margin-top: 50px;
}

.c-footer-bot-inr .links a {
  position: relative;
  font-size: 14px;
  font-weight: bold;
}
.c-footer-bot-inr .links a:after {
  position: absolute;
  top: 50%;
  width: 1px;
  height: 60%;
  content: "";
  background: #20211e;
  transform: translateY(-50%);
}
[data-browse-mode=P] .c-footer-bot-inr .links a:after {
  right: -15px;
}

[data-browse-mode=S] .c-footer-bot-inr .links a:after {
  right: -7.5px;
}

.c-footer-bot-inr .links a:last-child::after {
  display: none;
}
.c-footer-copy {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background: #88915a;
}
[data-browse-mode=P] .c-footer-copy {
  height: 42px;
  margin-top: 40px;
}

[data-browse-mode=S] .c-footer-copy {
  height: 60px;
  margin-top: 50px;
  text-align: center;
}

.c-acd-menu-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
  transition: all ease 0.3s;
}
.c-acd-menu-box:after {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: all ease 0.3s;
}
.c-acd-menu-box.lv1 {
  background: #f5f4f2;
  border-bottom: 2px solid #fff;
}
.c-acd-menu-box.lv2 {
  height: 0;
  overflow: hidden;
  line-height: 0;
  border-bottom: 0px solid #f5f4f2;
  opacity: 0;
}
.c-acd-menu-box.lv2:after {
  height: 0;
  opacity: 0;
}
.c-acd-menu-box.plus::after {
  background-image: url("/wwwroot/design/img/common/icn_acd_plus.png");
}
.c-acd-menu-box.arrow::after {
  background-image: url("/wwwroot/design/img/common/icn_acd_arrow.png");
}
.c-acd-menu-box.is-open {
  height: 50px;
  line-height: normal;
  border-bottom-width: 2px;
  opacity: 1;
}
.c-acd-menu-box.is-open::after {
  height: 14px;
  opacity: 1;
}
.c-acd-menu-box.is-open.plus::after {
  background-image: url("/wwwroot/design/img/common/icn_acd_minus.png");
}

.block-kv-slider {
  width: 100%;
}
[data-browse-mode=P] .block-kv-slider {
  margin-top: 30px;
}

[data-browse-mode=S] .block-kv-slider {
  margin-top: 20px;
}

[data-browse-mode=P] .block-kv-slider .splide__slide {
  width: 800px;
  height: 600px;
}

[data-browse-mode=S] .block-kv-slider .splide__slide {
  width: 350px;
  height: 400px;
  margin-right: 10px;
}

.block-kv-slider .splide__slide img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  transition: all ease 0.3s;
  object-fit: cover;
}
[data-browse-mode=P] .block-kv-slider .splide__slide img {
  transform: scale(0.9);
}

[data-browse-mode=S] .block-kv-slider .splide__slide img {
  transform: scale(1);
}

.block-kv-slider .splide__slide.is-active img {
  transform: scale(1);
}
.block-kv-slider .splide__arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 800px;
  transform: translate(-50%, -50%);
}
[data-browse-mode=P] .block-kv-slider .splide__arrows {
  display: block;
}

[data-browse-mode=S] .block-kv-slider .splide__arrows {
  display: none;
}

.block-kv-slider .splide__arrows .splide__arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  background: #5c6436;
  border-radius: 100vmax;
  transform: translateY(-50%);
}
.block-kv-slider .splide__arrows .splide__arrow--prev {
  left: -60px;
}
.block-kv-slider .splide__arrows .splide__arrow--next {
  right: -60px;
}
.block-kv-slider .splide__arrows .splide__arrow img {
  width: 14px;
}
.block-kv-slider .splide__pagination {
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
[data-browse-mode=P] .block-kv-slider .splide__pagination {
  display: none;
}

[data-browse-mode=S] .block-kv-slider .splide__pagination {
  display: flex;
}

.block-kv-slider .splide__pagination__page {
  width: 6px;
  height: 6px;
  background: #5c6436;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
.block-kv-slider .splide__pagination__page.is-active {
  background: #fff;
}

[data-browse-mode=P] .block-top-category {
  margin: 115px 0 100px;
}

[data-browse-mode=S] .block-top-category {
  margin: 45px 0 70px;
}

.block-top-category .ttl {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  text-align: center;
}
[data-browse-mode=P] .block-top-category .ttl {
  font-size: 34px;
}

[data-browse-mode=S] .block-top-category .ttl {
  font-size: 30px;
}

.block-top-category .sub {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  margin-top: 10px;
  color: #5c6436;
  text-align: center;
}
[data-browse-mode=P] .block-top-category .sub {
  font-size: 20px;
}

[data-browse-mode=S] .block-top-category .sub {
  font-size: 16px;
}

.block-top-category-slider {
  margin: 36px auto 0;
}
[data-browse-mode=P] .block-top-category-slider {
  width: 1000px;
}

[data-browse-mode=S] .block-top-category-slider {
  width: 100%;
  visibility: visible;
}

[data-browse-mode=S] .block-top-category-slider .splide__track {
  padding-bottom: 35px;
  overflow-x: scroll;
}
[data-browse-mode=S] .block-top-category-slider .splide__track .simplebar-scrollbar::before {
  height: 2px;
  background-color: #5c6436;
  opacity: 1;
}

[data-browse-mode=S] .block-top-category-slider .splide__list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);
  grid-auto-flow: column;
  gap: 50px 15px;
  width: max-content;
  padding: 0 15px !important;
}

[data-browse-mode=P] .block-top-category-slider .splide__slide {
  width: 120px;
}

.block-top-category-slider .splide__slide a {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}
.block-top-category-slider .splide__slide a img {
  border-radius: 100vmax;
  object-fit: cover;
}
[data-browse-mode=P] .block-top-category-slider .splide__slide a img {
  width: 120px;
  height: 120px;
}

[data-browse-mode=S] .block-top-category-slider .splide__slide a img {
  width: 100px;
  height: 100px;
}

.block-top-category-slider .splide__slide a .txt {
  font-family: "Zen Kaku Gothic New", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.block-top-category-slider .splide__slide a:hover .txt {
  color: #5c6436;
}
.block-top-category-slider .splide__arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 1000px;
  transform: translate(-50%, -50%);
}
.block-top-category-slider .splide__arrows .splide__arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #5c6436;
  border-radius: 100vmax;
  transform: translateY(-50%);
}
.block-top-category-slider .splide__arrows .splide__arrow--prev {
  left: -70px;
}
.block-top-category-slider .splide__arrows .splide__arrow--next {
  right: -70px;
}
.block-top-category-slider .splide__arrows .splide__arrow img {
  width: 12px;
}
.block-top-category .link {
  display: flex;
  justify-content: flex-end;
  width: 1000px;
  margin: 18px auto 0;
}
[data-browse-mode=S] .block-top-category .link {
  display: none;
}

.block-top-category .link a {
  display: flex;
  gap: 15px;
  align-items: center;
}
.block-top-category .link a img {
  width: 40px;
}
.block-top-category .link a .txt {
  font-size: 16px;
  font-weight: bold;
}

.block-top-topiclist {
  background-image: url("/wwwroot/design/img/common/bg_2.png");
}
[data-browse-mode=P] .block-top-topiclist {
  padding: 80px 0;
}

[data-browse-mode=S] .block-top-topiclist {
  padding: 55px 20px;
}

.block-top-topiclist-inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
[data-browse-mode=P] .block-top-topiclist-inr {
  width: 1080px;
}

[data-browse-mode=S] .block-top-topiclist-inr {
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.block-top-topiclist-head h2 {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  line-height: 1;
}
[data-browse-mode=P] .block-top-topiclist-head h2 {
  font-size: 34px;
}

[data-browse-mode=S] .block-top-topiclist-head h2 {
  font-size: 30px;
  text-align: center;
}

.block-top-topiclist-head .sub {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  margin-top: 10px;
  line-height: 1;
  color: #5c6436;
}
[data-browse-mode=P] .block-top-topiclist-head .sub {
  font-size: 20px;
}

[data-browse-mode=S] .block-top-topiclist-head .sub {
  font-size: 16px;
  text-align: center;
}

.block-top-topiclist-body {
  background: #fff;
  border-radius: 14px;
}
[data-browse-mode=P] .block-top-topiclist-body {
  width: 880px;
  height: 350px;
  padding: 30px;
}

[data-browse-mode=S] .block-top-topiclist-body {
  position: relative;
  width: 100%;
  padding: 15px;
  overflow: hidden;
}

.block-top-topiclist-body .topicline {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 20px;
}
.block-top-topiclist-body .topicline .simplebar-scrollbar::before {
  width: 3px;
  background-color: #5c6436;
  opacity: 1;
}
.block-top-topiclist-body .topicline li {
  list-style: none;
  border-bottom: 1px solid #e3e8d0;
}
[data-browse-mode=P] .block-top-topiclist-body .topicline li {
  padding: 15px 0 20px;
}

[data-browse-mode=S] .block-top-topiclist-body .topicline li {
  padding: 20px 0;
}

[data-browse-mode=P] .block-top-topiclist-body .topicline li:first-child {
  padding-top: 0;
}

[data-browse-mode=S] .block-top-topiclist-body .topicline li:first-child {
  padding-top: 30px;
}

.block-top-topiclist-body .topicline li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.block-top-topiclist-body .topicline li .head {
  display: flex;
  gap: 16px;
}
.block-top-topiclist-body .topicline li .head .date {
  font-size: 16px;
  font-weight: bold;
}
.block-top-topiclist-body .topicline li .head .label img {
  height: 22px;
}
.block-top-topiclist-body .topicline li .cont {
  margin-top: 15px;
}
[data-browse-mode=P] .block-top-topiclist-body .topicline li .cont a {
  font-size: 16px;
}
[data-browse-mode=P] .block-top-topiclist-body .topicline li .cont a:hover {
  color: #5c6436;
}

[data-browse-mode=S] .block-top-topiclist-body .topicline li .cont a {
  font-size: 14px;
}

[data-browse-mode=S] .block-top-topiclist-body .topicline {
  height: 450px;
  padding: 0;
  overflow: hidden;
  transition: all ease 0.3s;
}
[data-browse-mode=S] .block-top-topiclist-body .topicline .more {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  font-size: 18px;
  font-weight: bold;
  background: rgb(245, 244, 242);
  background: linear-gradient(0deg, rgb(245, 244, 242) 0%, rgba(245, 244, 242, 0.8925945378) 70%, rgba(9, 9, 121, 0) 100%);
}
[data-browse-mode=S] .block-top-topiclist-body .topicline .more::before {
  position: relative;
  width: 14px;
  height: 8px;
  content: "";
  background-image: url("/wwwroot/design/img/common/arrow_down_green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
[data-browse-mode=S] .block-top-topiclist-body .topicline.is-open {
  height: auto;
  padding-bottom: 100px;
}
[data-browse-mode=S] .block-top-topiclist-body .topicline.is-open .more:before {
  transform: rotate(180deg);
}

.event_ {
  position: relative;
  width: 100%;
  padding: 34px 0 64px;
}
.event_:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  background: #bdcb82;
  mix-blend-mode: soft-light;
}
.event_inr_ {
  position: relative;
  z-index: 2;
}
.event_ .free_event_title_ {
  padding: 0;
  margin: 40px auto;
  text-align: center;
  background: none;
}
[data-browse-mode=P] .event_ .free_event_title_ {
  width: 1080px;
}

[data-browse-mode=S] .event_ .free_event_title_ {
  width: 100%;
}

.event_ .free_event_title_:nth-child(n+2) {
  margin-top: 80px;
}
.event_ .free_event_title_ h2 {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  padding: 0;
  font-size: 34px;
  font-weight: bold;
  text-indent: 0;
  background: none;
}
.event_ .free_event_title_ .sub {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  font-size: 20px;
  color: #5c6436;
}

.goods_ {
  z-index: 1;
  padding-top: 20px;
  margin: 0 auto;
}
[data-browse-mode=P] .goods_ {
  width: 1080px !important;
  overflow: hidden;
}

[data-browse-mode=S] .goods_ {
  width: 100%;
  padding-bottom: 30px;
}
[data-browse-mode=S] .goods_ .simplebar-scrollbar::before {
  height: 2px;
  background-color: #5c6436;
  opacity: 1;
}

.goods_ .StyleT_Frame_,
.goods_ .StyleR_Frame_ {
  margin: 0;
}
[data-browse-mode=P] .goods_ .StyleT_Frame_ {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  overflow: visible;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  overflow: visible;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ {
  display: flex;
  gap: 20px;
  width: max-content;
  padding: 0 20px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ {
  display: flex;
  gap: 20px;
  width: max-content;
  padding: 0 20px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_,
.goods_ .StyleT_Frame_ .StyleR_Item_,
.goods_ .StyleR_Frame_ .StyleT_Item_,
.goods_ .StyleR_Frame_ .StyleR_Item_ {
  position: relative;
  margin: 0;
}
[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleT_Item_ {
  width: auto;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleR_Item_ {
  width: auto;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleT_Item_ {
  width: auto;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleR_Item_ {
  width: auto;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ {
  width: 150px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_ .rank_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .rank_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .rank_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .rank_ {
  font-family: quincy-cf, serif !important;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 46px;
  font-size: 24px;
  line-height: 1;
  background-image: url("/wwwroot/design/img/common/ranking_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .img_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .img_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .img_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .img_ {
  position: relative;
  height: auto;
  aspect-ratio: 1/1;
  overflow: visible;
  background: none;
}
[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleT_Item_ .img_ {
  width: 100%;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleR_Item_ .img_ {
  width: 100%;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleT_Item_ .img_ {
  width: 100%;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleR_Item_ .img_ {
  width: 100%;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .img_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .img_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .img_ {
  width: 150px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .img_ {
  width: 150px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_ .img_ a.goods_name_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .img_ a.goods_name_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .img_ a.goods_name_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .img_ a.goods_name_ {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .img_ a.goods_name_ img,
.goods_ .StyleT_Frame_ .StyleR_Item_ .img_ a.goods_name_ img,
.goods_ .StyleR_Frame_ .StyleT_Item_ .img_ a.goods_name_ img,
.goods_ .StyleR_Frame_ .StyleR_Item_ .img_ a.goods_name_ img {
  width: 100%;
  max-width: none;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ {
  min-height: auto !important;
  margin-bottom: 0;
}
[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ {
  margin-top: 10px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ {
  margin-top: 10px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ {
  margin-top: 10px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ {
  margin-top: 10px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .name_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .name_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .name_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .name_ {
  font-weight: bold;
}
[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .name_ {
  font-size: 14px;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .name_ {
  font-size: 14px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .name_ {
  font-size: 14px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .name_ {
  font-size: 14px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .name_ {
  font-size: 13px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .name_ {
  font-size: 13px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .name_ {
  font-size: 13px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .name_ {
  font-size: 13px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .name_ a:hover,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .name_ a:hover,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .name_ a:hover,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .name_ a:hover {
  color: #5c6436;
  text-decoration: underline;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .box1,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .box1,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .box1,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .box1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .color_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .color_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .color_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .color_ {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .desc_ .color_ img,
.goods_ .StyleT_Frame_ .StyleR_Item_ .desc_ .color_ img,
.goods_ .StyleR_Frame_ .StyleT_Item_ .desc_ .color_ img,
.goods_ .StyleR_Frame_ .StyleR_Item_ .desc_ .color_ img {
  width: 18px;
  height: 18px;
  border-radius: 100vmax;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .review,
.goods_ .StyleT_Frame_ .StyleR_Item_ .review,
.goods_ .StyleR_Frame_ .StyleT_Item_ .review,
.goods_ .StyleR_Frame_ .StyleR_Item_ .review {
  margin-top: 15px;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .price_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .price_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .price_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .price_ {
  margin: 15px 0 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}
[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .price_ {
  color: #20211e;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .price_ {
  color: #20211e;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .price_ {
  color: #20211e;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .price_ {
  color: #20211e;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleT_Item_ .price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleR_Item_ .price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleT_Item_ .price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleR_Item_ .price_ span {
  font-size: 20px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .price_ span {
  font-size: 18px;
}

.goods_ .StyleT_Frame_ .StyleT_Item_ .price_.is-sale,
.goods_ .StyleT_Frame_ .StyleR_Item_ .price_.is-sale,
.goods_ .StyleR_Frame_ .StyleT_Item_ .price_.is-sale,
.goods_ .StyleR_Frame_ .StyleR_Item_ .price_.is-sale {
  font-weight: normal;
}
.goods_ .StyleT_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_,
.goods_ .StyleT_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_,
.goods_ .StyleR_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_,
.goods_ .StyleR_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_ {
  font-weight: bold;
  color: #ef3f15;
}
[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleT_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_ span {
  font-size: 20px;
}

[data-browse-mode=P] .goods_ .StyleR_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_ span {
  font-size: 20px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleT_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleT_Item_ .price_.is-sale .sale_price_ span {
  font-size: 18px;
}

[data-browse-mode=S] .goods_ .StyleR_Frame_ .StyleR_Item_ .price_.is-sale .sale_price_ span {
  font-size: 18px;
}

.block-top-hotKeyword {
  background-image: url("/wwwroot/design/img/common/bg_2.png");
}
[data-browse-mode=P] .block-top-hotKeyword {
  padding: 80px 0;
}

[data-browse-mode=S] .block-top-hotKeyword {
  padding: 60px 0 70px;
}

.block-top-hotKeyword-inr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
[data-browse-mode=P] .block-top-hotKeyword-inr {
  width: 1080px;
}

[data-browse-mode=S] .block-top-hotKeyword-inr {
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.block-top-hotKeyword-head h2 {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  line-height: 1;
}
[data-browse-mode=P] .block-top-hotKeyword-head h2 {
  font-size: 34px;
}

[data-browse-mode=S] .block-top-hotKeyword-head h2 {
  font-size: 30px;
  text-align: center;
}

.block-top-hotKeyword-head .sub {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  margin-top: 10px;
  line-height: 1;
  color: #5c6436;
}
[data-browse-mode=P] .block-top-hotKeyword-head .sub {
  font-size: 20px;
}

[data-browse-mode=S] .block-top-hotKeyword-head .sub {
  font-size: 16px;
  text-align: center;
}

[data-browse-mode=P] .block-top-hotKeyword-body {
  width: 880px;
}

[data-browse-mode=S] .block-top-hotKeyword-body {
  width: 100%;
  padding: 0 20px;
}

.block-top-hotKeyword-body .keywords {
  display: flex;
  flex-wrap: wrap;
}
[data-browse-mode=P] .block-top-hotKeyword-body .keywords {
  gap: 20px;
}

[data-browse-mode=S] .block-top-hotKeyword-body .keywords {
  gap: 10px;
  justify-content: center;
}

.block-top-hotKeyword-body .keywords a.keyword {
  line-height: 1;
  background: #fff;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
[data-browse-mode=P] .block-top-hotKeyword-body .keywords a.keyword {
  padding: 12px 30px;
  font-size: 16px;
  font-weight: bold;
}

[data-browse-mode=S] .block-top-hotKeyword-body .keywords a.keyword {
  padding: 10px;
  font-size: 13px;
}

.block-top-hotKeyword-body .keywords a.keyword:hover {
  color: #fff;
  background: #5c6436;
  opacity: 1;
}

[data-browse-mode=S] .block-top-feature-inr {
  margin-top: 70px;
}

.block-top-feature-slider {
  width: 1000px;
  margin: 50px auto 0;
}
[data-browse-mode=S] .block-top-feature-slider {
  width: 100%;
  visibility: visible;
}

[data-browse-mode=S] .block-top-feature-slider .splide__track {
  padding-bottom: 30px;
  overflow-x: scroll;
}

.block-top-feature-slider .splide__track .simplebar-scrollbar::before {
  height: 2px;
  background-color: #5c6436;
  opacity: 1;
}
[data-browse-mode=S] .block-top-feature-slider .splide__list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  gap: 25px 15px;
  width: max-content;
  padding: 0 20px !important;
}

.block-top-feature-slider .splide__slide {
  display: flex;
  flex-direction: column;
}
[data-browse-mode=S] .block-top-feature-slider .splide__slide {
  width: 200px;
}

.block-top-feature-slider .splide__slide a img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}
[data-browse-mode=S] .block-top-feature-slider .splide__slide a img {
  width: 200px;
  height: 180px;
}

.block-top-feature-slider .splide__slide a .sub {
  line-height: 1;
  word-break: keep-all;
  overflow-wrap: break-word;
}
[data-browse-mode=P] .block-top-feature-slider .splide__slide a .sub {
  margin-top: 16px;
  font-size: 12px;
}

[data-browse-mode=S] .block-top-feature-slider .splide__slide a .sub {
  margin-top: 15px;
  font-size: 12px;
}

.block-top-feature-slider .splide__slide a .ttl {
  margin-top: 10px;
  font-weight: bold;
  line-height: 1;
  word-break: keep-all;
  overflow-wrap: break-word;
}
[data-browse-mode=P] .block-top-feature-slider .splide__slide a .ttl {
  font-size: 18px;
}

[data-browse-mode=S] .block-top-feature-slider .splide__slide a .ttl {
  font-size: 16px;
}

.block-top-feature-slider .splide__slide a:hover .sub,
.block-top-feature-slider .splide__slide a:hover .ttl {
  color: #5c6436;
}
.block-top-feature-slider .splide__arrows {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-between;
  width: 1000px;
  transform: translate(-50%, -50%);
}
.block-top-feature-slider .splide__arrows .splide__arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #5c6436;
  border-radius: 100vmax;
  transform: translateY(-50%);
}
.block-top-feature-slider .splide__arrows .splide__arrow--prev {
  left: -70px;
}
.block-top-feature-slider .splide__arrows .splide__arrow--next {
  right: -70px;
}
.block-top-feature-slider .splide__arrows .splide__arrow img {
  width: 12px;
}
.block-top-feature-more {
  margin-bottom: 80px;
}

.block-top-search {
  padding: 0 0 80px;
}
[data-browse-mode=S] .block-top-search-main {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 0 20px;
}
[data-browse-mode=S] .block-top-search-main .cont {
  overflow: hidden;
  background: #fff;
  border-radius: 14px;
}
[data-browse-mode=S] .block-top-search-main .cont .ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  background: #e3e8d0;
}
[data-browse-mode=S] .block-top-search-main .cont .main {
  padding: 30px 25px 20px;
}
[data-browse-mode=S] .block-top-search-main .cont .main .track {
  width: 100%;
  padding-bottom: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
}
[data-browse-mode=S] .block-top-search-main .cont .main .track .simplebar-scrollbar::before {
  height: 2px;
  background-color: #5c6436;
  opacity: 1;
}
[data-browse-mode=S] .block-top-search-main .cont-1 .main .track .list,
[data-browse-mode=S] .block-top-search-main .cont-4 .main .track .list,
[data-browse-mode=S] .block-top-search-main .cont-5 .main .track .list {
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;
  gap: 10px 20px;
}
[data-browse-mode=S] .block-top-search-main .cont-1 .main .track .list a,
[data-browse-mode=S] .block-top-search-main .cont-4 .main .track .list a,
[data-browse-mode=S] .block-top-search-main .cont-5 .main .track .list a {
  display: flex;
  align-items: center;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
}
[data-browse-mode=S] .block-top-search-main .cont-1 .main .track .list a::before,
[data-browse-mode=S] .block-top-search-main .cont-4 .main .track .list a::before,
[data-browse-mode=S] .block-top-search-main .cont-5 .main .track .list a::before {
  position: relative;
  display: inline-block;
  width: 4px;
  height: 100%;
  margin-right: 15px;
  content: "";
  background-image: url("/wwwroot/design/img/common/arrow_next_green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
[data-browse-mode=S] .block-top-search-main .cont-2 .main {
  padding-bottom: 0;
}
[data-browse-mode=S] .block-top-search-main .cont-2 .main .track .list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
  gap: 30px 10px;
}
[data-browse-mode=S] .block-top-search-main .cont-2 .main .track .list a {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100px;
}
[data-browse-mode=S] .block-top-search-main .cont-2 .main .track .list a .img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #e3e8d0;
  border-radius: 100vmax;
}
[data-browse-mode=S] .block-top-search-main .cont-2 .main .track .list a .img img {
  width: 80%;
}
[data-browse-mode=S] .block-top-search-main .cont-3 .main .track {
  overflow-x: scroll;
  overflow-y: hidden;
}
[data-browse-mode=S] .block-top-search-main .cont-3 .main .track .keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 600px;
}
[data-browse-mode=S] .block-top-search-main .cont-3 .main .track .keywords a.keyword {
  padding: 11px 10px;
  font-size: 13px;
  line-height: 1;
  background: #fff;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
[data-browse-mode=S] .block-top-search-main .cont-5 .main .track .list a {
  width: 160px;
}
[data-browse-mode=S] .block-top-search-more {
  margin-bottom: 80px;
}

.block-top-revico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 1px solid #000;
}

.block-top-visumo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 1px solid #000;
}

.block-top-about {
  width: 100%;
}
[data-browse-mode=P] .block-top-about {
  padding: 80px 0;
}

[data-browse-mode=S] .block-top-about {
  padding: 50px 20px;
}

.block-top-about-inr {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  background: #e3e8d0;
  background-image: url("/wwwroot/design/img/common/bg_3.png");
  background-position: center;
  background-size: cover;
}
[data-browse-mode=P] .block-top-about-inr {
  width: 1080px;
  padding: 65px 90px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

[data-browse-mode=S] .block-top-about-inr {
  width: 100%;
  padding: 40px 15px 128px;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
}

.block-top-about-main .ttl {
  font-family: "Yeseva One", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  line-height: 1;
  color: #5c6436;
  text-align: center;
}
[data-browse-mode=P] .block-top-about-main .ttl {
  font-size: 60px;
}

[data-browse-mode=S] .block-top-about-main .ttl {
  font-size: 36px;
}

.block-top-about-main .sub {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}
[data-browse-mode=P] .block-top-about-main .sub {
  margin-top: 10px;
  font-size: 18px;
}

[data-browse-mode=S] .block-top-about-main .sub {
  margin-top: 7px;
  font-size: 16px;
}

[data-browse-mode=P] .block-top-about-main .main {
  margin-top: 35px;
}

[data-browse-mode=S] .block-top-about-main .main {
  margin-top: 45px;
}

.block-top-about-main .main p {
  font-family: "Zen Kaku Gothic New", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
  margin-top: 30px;
  line-height: 1.7;
  text-align: center;
}
[data-browse-mode=P] .block-top-about-main .main p {
  font-size: 16px;
}

[data-browse-mode=S] .block-top-about-main .main p {
  font-size: 14px;
  font-weight: bold;
}

.block-top-about-main .more {
  text-align: center;
}
[data-browse-mode=P] .block-top-about-main .more {
  margin-top: 40px;
}

[data-browse-mode=S] .block-top-about-main .more {
  margin-top: 37px;
}

.block-top-about-main .more a {
  display: inline-flex;
  gap: 15px;
  align-items: center;
}
.block-top-about-main .more a img {
  width: 40px;
}
.block-top-about-main .more a .txt {
  font-size: 16px;
  font-weight: bold;
}
.block-top-about-main .visual {
  position: absolute;
}
[data-browse-mode=P] .block-top-about-main .visual {
  right: 60px;
  bottom: 30px;
}

[data-browse-mode=S] .block-top-about-main .visual {
  right: 20px;
  bottom: 30px;
}

[data-browse-mode=P] .block-top-about-main .visual img {
  width: 100px;
}

[data-browse-mode=S] .block-top-about-main .visual img {
  width: 90px;
}

[data-browse-mode=P] .block-top-recently {
  margin: 20px 0 140px;
}

[data-browse-mode=S] .block-top-recently {
  margin: 20px 0 130px;
}

.block-top-recently-inr {
  margin: 0 auto;
}
[data-browse-mode=P] .block-top-recently-inr {
  width: 1080px;
}

[data-browse-mode=S] .block-top-recently-inr {
  width: 100%;
}

.block-top-recently-inr .ttl h2 {
  font-family: "Kaisei Opti", "Hiragino Kaku Gothic ProN", "Hiragino Sans", "Noto Sans JP", "Helvetica Neue", Arial, "Roboto", Meiryo, sans-serif;
}
[data-browse-mode=P] .block-top-recently-inr .ttl h2 {
  font-size: 28px;
}

[data-browse-mode=S] .block-top-recently-inr .ttl h2 {
  padding: 0 20px;
  font-size: 24px;
}

[data-browse-mode=P] .block-top-recently-inr .goods_ {
  margin-top: 45px;
}

[data-browse-mode=S] .block-top-recently-inr .goods_ {
  margin-top: 24px;
}

.block-top-recently-inr .misc {
  margin-top: 30px;
  text-align: right;
}
[data-browse-mode=S] .block-top-recently-inr .misc {
  padding-right: 20px;
}

.block-top-recently-inr .misc a {
  font-size: 16px;
  font-weight: bold;
  color: #5c6436;
  text-decoration: underline;
}

.block-search {
  width: 1080px;
  height: 560px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 20px;
}
.block-search .tabs {
  display: flex;
  width: 100%;
}
.block-search .tabs .tab {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  background: #e3e8d0;
}
.block-search .tabs .tab:nth-child(odd) {
  background: #f5f4f2;
}
.block-search .tabs .tab.is-active {
  color: #fff;
  background: #5c6436;
}
.block-search .tabs .tab.is-active:after {
  position: absolute;
  bottom: -12px;
  left: 50%;
  z-index: 9;
  width: 0;
  height: 0;
  content: "";
  border-color: #5c6436 transparent transparent transparent;
  border-style: solid;
  border-width: 13px 14px 0 14px;
  transform: translateX(-50%);
}
.block-search .tabs .tab:hover {
  z-index: 9;
  opacity: 0.5;
}
.block-search .conts {
  position: relative;
  width: 100%;
  height: 480px;
  background: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.block-search .conts .cont {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  grid-template-rows: repeat(10, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;
  gap: 18px;
  width: 100%;
  height: 100%;
  padding: 60px 50px 70px;
}
.block-search .conts .cont.is-active {
  display: grid;
}
.block-search .conts .cont-1 a,
.block-search .conts .cont-4 a,
.block-search .conts .cont-5 a {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}
.block-search .conts .cont-1 a::before,
.block-search .conts .cont-4 a::before,
.block-search .conts .cont-5 a::before {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 5px;
  height: 100%;
  margin-right: 15px;
  content: "";
  background-image: url("/wwwroot/design/img/common/arrow_next_green.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.block-search .conts .cont-1 a:hover,
.block-search .conts .cont-4 a:hover,
.block-search .conts .cont-5 a:hover {
  color: #5c6436;
}
.block-search .conts .cont-2.is-active {
  display: block;
}
.block-search .conts .cont-2 .list {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(7, 1fr);
  grid-auto-flow: row;
  gap: 18px 20px;
}
.block-search .conts .cont-2 .list a {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.block-search .conts .cont-2 .list a .img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 1/1;
  border: 1px solid #e3e8d0;
  border-radius: 100vmax;
}
.block-search .conts .cont-2 .list a .img img {
  width: 90%;
}
.block-search .conts .cont-2 .list a .txt {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
}
.block-search .conts .cont-2 .list a:hover .txt {
  color: #5c6436;
}
.block-search .conts .cont-2 .more {
  margin-top: 30px;
}
.block-search .conts .cont-2 .more a {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
}
.block-search .conts .cont-2 .more a img {
  width: 40px;
}
.block-search .conts .cont-2 .more a .txt {
  font-size: 14px;
  font-weight: bold;
}
.block-search .conts .cont-3.is-active {
  display: block;
}
.block-search .conts .cont-3 .keywords {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.block-search .conts .cont-3 .keywords a.keyword {
  padding: 12px 30px;
  font-weight: bold;
  line-height: 1;
  background: #fff;
  border: 1px solid #5c6436;
  border-radius: 100vmax;
}
.block-search .conts .cont-3 .keywords a.keyword:hover {
  color: #fff;
  background: #5c6436;
  opacity: 1;
}