* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* smartpn.css override */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  @include sp(&) {
    font-size: 14px;
  }
}

html {
  scroll-behavior: smooth;
  overflow-x: auto;
}

:root {
  scroll-padding: 30px;
}

body {
  @include f-body;
  position: relative;
  font-size: 14px;
  line-height: normal;
  color: $c-black;
  letter-spacing: 0.03em;
  word-break: break-all;
  @include pc(&) {
    min-width: 1120px;
  }
  @include sp(&) {
    width: 100%;
    min-width: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

div.wrapper_ {
  overflow-x: hidden;
  overflow-y: clip;
  background: url('/wwwroot/design/img/common/bg_1.png') repeat left top;
  .container_ {
    padding: 0;
    .contents_ {
      width: auto;
      .mainframe_ {
        float: none;
        width: auto;
        margin: 0;
      }
    }
  }
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.05em;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    opacity: 0.6;
  }
}

p {
  line-height: 1.6;
  @include sp(&) {
    letter-spacing: normal;
  }
}

img {
  image-rendering: -webkit-optimize-contrast;
}

button {
  display: block;
  cursor: pointer;
  background: none;
  border: none;
}

select,
select * {
  box-sizing: border-box;
  min-width: 1.4em;
  min-height: 1.4em;
  padding: 0;
  padding: 0.2em 0.5em;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  vertical-align: middle;
  background: #fff;
  background-color: transparent;
  border: 0;
  border: 1px solid #6d453b;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

*:focus-visible {
  outline: none;
}
