#footer {
  height: auto;
  background: none;
  .change_mode_ {
    display: none;
  }
}

.c-pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 92px;
  height: 183px;
  margin: 0 auto 20px;
  opacity: 0;
  transition: all ease 0.3s;
  a {
    display: block;
    width: 92px;
    height: 183px;
    margin: 0 50px 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
    @include sp(&) {
      right: 0;
      margin: 0 15px 0 auto;
    }
  }
  &.is-show {
    opacity: 1;
  }
}

.c-footer {
  background: $c-white;
  @include pc(&) {
    margin-top: 50px;
  }
  @include sp(&) {
    margin-top: 100px;
  }
  a {
    &:hover {
      color: $c-green;
    }
  }
  .c-acd-menu {
    &-box {
      &.is-open {
        &.lv2 {
          background: $c-gray3;
          border-bottom: 2px solid $c-white;
        }
      }
      &.lv1 {
        background: $c-white;
        border-bottom: 2px solid $c-gray3;
      }
    }
  }

  &-main {
    margin: 0 auto;
    @include pc(&) {
      width: 1080px;
      padding-top: 50px;
    }
    @include sp(&) {
      width: 100%;
    }
    &-inr {
      display: flex;
      justify-content: space-between;
      @include sp(&) {
        flex-direction: column;
      }
    }
    &-left {
      @include pc(&) {
        display: flex;
        gap: 50px;
        .box {
          display: flex;
          flex-direction: column;
          .ttl {
            display: flex;
            gap: 8px;
            align-items: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            &:first-child {
              margin-top: 0;
            }
            img {
              height: 8px;
            }
          }
          .link {
            margin-top: 10px;
            font-size: 14px;
          }
        }
      }
      @include sp(&) {
        width: 100%;
      }
    }
    &-right {
      @include pc(&) {
        width: 400px;
      }
      @include sp(&) {
        padding: 0 20px;
        margin-top: 50px;
      }
      .top {
        display: none;
        a {
          font-weight: bold;
          @include pc(&) {
            font-size: 16px;
          }
          @include sp(&) {
            font-size: 18px;
          }
        }
      }
      .mid {
        // display: flex;
        display: none;
        gap: 20px;
        @include pc(&) {
          margin-top: 30px;
        }
        @include sp(&) {
          flex-direction: column;
          margin-top: 20px;
        }
        .box {
          @include sp(&) {
            display: flex;
            gap: 10px;
            align-items: center;
          }
          img {
            display: block;
            height: 12px;
          }
          a {
            display: block;
            font-size: 12px;
            font-weight: bold;
          }
          &.mail {
            @include pc(&) {
              width: 180px;
            }
            @include sp(&) {
            }
            a {
              letter-spacing: 0.5px;
              @include pc(&) {
                margin-top: 8px;
              }
            }
          }
          &.tel {
            a {
              font-size: 18px;
              @include pc(&) {
                margin-top: 4px;
              }
            }
          }
        }
      }
      .bot {
        // @include pc(&) {
        //   margin-top: 30px;
        // }
        // @include sp(&) {
        //   margin-top: 40px;
        // }
        .ttl {
          font-size: 16px;
          font-weight: bold;
        }
        .date {
          margin-top: 14px;
          font-weight: bold;
          @include pc(&) {
            font-size: 14px;
          }
          @include sp(&) {
            font-size: 16px;
            text-align: center;
          }
        }
        .box {
          display: flex;
          gap: 20px;
          margin-top: 8px;
          @include sp(&) {
            flex-direction: column;
          }
          table.calendar {
            border-spacing: 1px;
            border-collapse: initial;
            background: $c-green3;
            @include pc(&) {
              width: 180px;
            }
            @include sp(&) {
              width: 250px;
              margin: 0 auto;
            }
            tbody {
              tr {
                th {
                  color: $c-white;
                  text-align: center;
                  background: $c-green;
                  @include pc(&) {
                    width: 26px;
                    height: 26px;
                  }
                  @include sp(&) {
                    width: 36px;
                    height: 36px;
                  }
                }
                td {
                  text-align: center;
                  background: $c-white;
                  @include pc(&) {
                    width: 26px;
                    height: 26px;
                  }
                  @include sp(&) {
                    width: 36px;
                    height: 36px;
                  }
                  &.holiday {
                    background: $c-gray2;
                  }
                }
              }
            }
          }
          .note {
            flex: 1;
            font-weight: bold;
            @include pc(&) {
              font-size: 12px;
            }
            @include sp(&) {
              font-size: 14px;
            }
            .highlight {
              display: inline-block;
              width: 1em;
              height: 1em;
              background: $c-gray2;
            }
          }
        }
      }
    }
  }
  &-bot {
    margin-top: 50px;
    &-inr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      @include pc(&) {
        width: 1080px;
      }
      @include sp(&) {
        flex-direction: column;
        width: 100%;
      }
      .sns {
        display: flex;
        gap: 10px;
        a {
          img {
            width: 36px;
            height: 36px;
          }
        }
      }
      .links {
        display: flex;
        align-items: center;
        @include pc(&) {
          gap: 30px;
        }
        @include sp(&) {
          flex-wrap: wrap;
          gap: 15px;
          justify-content: center;
          width: 80%;
          margin-top: 50px;
        }
        a {
          position: relative;
          font-size: 14px;
          font-weight: bold;
          &:after {
            position: absolute;
            top: 50%;
            width: 1px;
            height: 60%;
            content: '';
            background: $c-black;
            transform: translateY(-50%);
            @include pc(&) {
              right: -15px;
            }
            @include sp(&) {
              right: -7.5px;
            }
          }
          &:last-child::after {
            display: none;
          }
        }
      }
    }
  }
  &-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 12px;
    font-weight: bold;
    color: $c-white;
    background: $c-green2;
    @include pc(&) {
      height: 42px;
      margin-top: 40px;
    }
    @include sp(&) {
      height: 60px;
      margin-top: 50px;
      text-align: center;
    }
  }
}

// c-acd-menu
.c-acd-menu {
  &-inr {
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
    transition: all ease 0.3s;
    &:after {
      position: relative;
      display: block;
      width: 14px;
      height: 14px;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: all ease 0.3s;
    }
    &.lv1 {
      background: $c-gray3;
      border-bottom: 2px solid $c-white;
    }
    &.lv2 {
      height: 0;
      overflow: hidden;
      line-height: 0;
      border-bottom: 0px solid $c-gray3;
      opacity: 0;
      &:after {
        height: 0;
        opacity: 0;
      }
    }
    &.plus {
      &::after {
        background-image: url('/wwwroot/design/img/common/icn_acd_plus.png');
      }
    }
    &.arrow {
      &::after {
        background-image: url('/wwwroot/design/img/common/icn_acd_arrow.png');
      }
    }
    &.is-open {
      height: 50px;
      line-height: normal;
      border-bottom-width: 2px;
      opacity: 1;
      &::after {
        height: 14px;
        opacity: 1;
      }
      &.plus {
        &::after {
          background-image: url('/wwwroot/design/img/common/icn_acd_minus.png');
        }
      }
    }
  }
}
