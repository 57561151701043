@use 'sass:selector';
@use 'sass:map';

@mixin sp($selector: '') {
  $selectors: selector.parse($selector);
  @each $elm in $selectors {
    @at-root [data-browse-mode='S'] #{$elm} {
      @content;
    }
  }
}

@mixin pc($selector: '') {
  $selectors: selector.parse($selector);
  @each $elm in $selectors {
    @at-root [data-browse-mode='P'] #{$elm} {
      @content;
    }
  }
}
