/**************************\
  Basic Modal Styles
\**************************/
.body-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
}

.modal__container {
  position: relative;
  box-sizing: border-box;
  max-height: 80%;
  padding: 40px 30px;
  overflow-y: auto;
  background-color: #fff;
  @include pc(&) {
    width: 700px;
    border-radius: 20px;
  }
  @include sp(&) {
    width: calc(100% - 40px);
    border-radius: 14px;
  }
}

.modal__header {
  padding-bottom: 23px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 2px solid $c-green3;
}

.modal__content {
  margin-top: 20px;
  h4 {
    font-size: 16px;
    font-weight: bold;
    color: $c-green;
    @include pc(&) {
      margin-top: 30px;
    }
    @include sp(&) {
      margin-top: 40px;
    }
  }
  .modal__boxlist {
    gap: 10px;
    margin-top: 20px;
    @include pc(&) {
      display: flex;
      justify-content: space-between;
    }
    @include sp(&) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      color: $c-green;
      border: 1px solid $c-green;
      border-radius: 4px;
    }
  }
  .modal__cardbrand {
    margin-top: 20px;
    img {
      height: 43px;
    }
  }
  .modal__atobarai {
    margin-top: 12px;
    @include pc(&) {
    }
    @include sp(&) {
      display: flex;
      justify-content: center;
    }
    img {
      height: 44px;
    }
  }
  .modal__note {
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    background: $c-green3;
    @include pc(&) {
      height: 40px;
    }
    @include sp(&) {
      line-height: 1.2;
    }
    span {
      color: $c-orange2;
    }
  }
  .modal_timebox {
    display: flex;
    margin-top: 20px;
    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: bold;
      color: $c-green;
      text-align: center;
      border-color: $c-green;
      border-style: solid;
      border-width: 1px 0px 1px 1px;
      @include pc(&) {
        width: 80px;
        height: 80px;
      }
      @include sp(&) {
        width: 50px;
        height: 50px;
      }
      &:last-child {
        border-width: 1px;
      }
    }
  }
  .modal_iconGrid {
    margin-top: 24px;
    @include pc(&) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 13px;
    }
    @include sp(&) {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    img {
      height: 28px;
    }
    .box {
      display: flex;
      gap: 20px;
      .txt {
        font-size: 14px;
      }
      &.l-2 {
        display: block;
        .flex {
          display: flex;
          gap: 5px;
          align-items: center;
          &:nth-child(2) {
            margin-top: 5px;
          }
          img {
            display: inline-block;
          }
          .txt {
            display: inline-block;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .modal_alignCenter {
    @include pc(&) {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    @include sp(&) {
    }
    img {
      height: 28px;
      @include pc(&) {
      }
      @include sp(&) {
        display: inline-block;
        margin-top: 20px;
        margin-right: 5px;
      }
    }
    .txt {
      @include pc(&) {
        margin-top: 4px;
        margin-left: 15px;
      }
      @include sp(&) {
        margin-top: 20px;
      }
    }
  }
  & > p {
    margin-top: 20px;
    @include pc(&) {
    }
    @include sp(&) {
      font-size: 15px;
    }
  }
  & > a {
    display: block;
    margin-top: 20px;
    color: $c-green;
    text-decoration: underline;
  }
  & > .modal__more {
    display: block;
    margin: 30px auto 0;
    font-size: 14px;
    line-height: 50px;
    color: $c-green;
    text-align: center;
    text-decoration: none;
    border: solid 2px $c-green;
    border-radius: 35px;
    @include pc(&) {
      width: 180px;
    }
    @include sp(&) {
      width: 170px;
    }
  }
}

.modal_close {
  position: absolute;
  @include pc(&) {
    width: 650px;
    margin: -20px 0 0 auto;
  }
  @include sp(&) {
    width: calc(100vw - 80px);
    margin: -30px 0 0 auto;
  }
  button {
    width: 20px;
    height: 20px;
    margin: 0 0 0 auto;
    img {
      width: 100%;
    }
  }
}

.modal__important {
  position: relative;
  max-width: 400px;
  margin: 0 auto 25px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  background: $c-orange;
  border-radius: 30px;
  &:before {
    position: absolute;
    top: 50%;
    width: 22px;
    height: 22px;
    content: '';
    background-image: url('/wwwroot/design/img/common/attend.png');
    background-position: center;
    background-size: cover;
    transform: translateY(-50%);
  }
  @include pc(&) {
    padding-left: 42px;
    font-size: 18px;
    &:before {
      margin-left: -42px;
    }
  }
  @include sp(&) {
    padding-left: 30px;
    font-size: 16px;
    &:before {
      margin-left: -30px;
    }
  }
}
.modal__emphasis {
  color: $c-green;
}

/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden='false'] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='false'] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden='true'] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
