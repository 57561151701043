.block-kv-slider {
  width: 100%;
  @include pc(&) {
    margin-top: 30px;
  }
  @include sp(&) {
    margin-top: 20px;
  }
  .splide__slide {
    @include pc(&) {
      width: 800px;
      height: 600px;
    }
    @include sp(&) {
      width: 350px;
      height: 400px;
      margin-right: 10px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      transition: all ease 0.3s;
      object-fit: cover;
      @include pc(&) {
        transform: scale(0.9);
      }
      @include sp(&) {
        transform: scale(1);
      }
    }
    &.is-active {
      img {
        transform: scale(1);
      }
    }
  }
  .splide__arrows {
    @include pc(&) {
      display: block;
    }
    @include sp(&) {
      display: none;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    width: 800px;
    transform: translate(-50%, -50%);
    .splide__arrow {
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 76px;
      height: 76px;
      background: $c-green;
      border-radius: 100vmax;
      transform: translateY(-50%);
      &--prev {
        left: -60px;
      }
      &--next {
        right: -60px;
      }
      img {
        width: 14px;
      }
    }
  }
  .splide__pagination {
    @include pc(&) {
      display: none;
    }
    @include sp(&) {
      display: flex;
    }
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    &__page {
      width: 6px;
      height: 6px;
      background: $c-green;
      border: 1px solid $c-green;
      border-radius: 100vmax;
      &.is-active {
        background: $c-white;
      }
    }
  }
}

.block-top-category {
  @include pc(&) {
    margin: 115px 0 100px;
  }
  @include sp(&) {
    margin: 45px 0 70px;
  }
  .ttl {
    @include f-KaiseiOpti;
    text-align: center;
    @include pc(&) {
      font-size: 34px;
    }
    @include sp(&) {
      font-size: 30px;
    }
  }
  .sub {
    @include f-YesevaOne;
    margin-top: 10px;
    color: $c-green;
    text-align: center;
    @include pc(&) {
      font-size: 20px;
    }
    @include sp(&) {
      font-size: 16px;
    }
  }
  &-slider {
    margin: 36px auto 0;
    @include pc(&) {
      width: 1000px;
    }
    @include sp(&) {
      width: 100%;
      visibility: visible;
    }
    .splide__track {
      @include sp(&) {
        padding-bottom: 35px;
        overflow-x: scroll;
        .simplebar-scrollbar::before {
          height: 2px;
          background-color: $c-green;
          opacity: 1;
        }
      }
    }
    .splide__list {
      @include sp(&) {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: column;
        gap: 50px 15px;
        width: max-content;
        padding: 0 15px !important;
      }
    }
    .splide__slide {
      @include pc(&) {
        width: 120px;
      }
      a {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: center;
        img {
          border-radius: 100vmax;
          object-fit: cover;
          @include pc(&) {
            width: 120px;
            height: 120px;
          }
          @include sp(&) {
            width: 100px;
            height: 100px;
          }
        }
        .txt {
          @include f-ZenKakuGothicNew;
          font-size: 16px;
          font-weight: bold;
        }
        &:hover {
          .txt {
            color: $c-green;
          }
        }
      }
    }
    .splide__arrows {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: space-between;
      width: 1000px;
      transform: translate(-50%, -50%);
      .splide__arrow {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background: $c-green;
        border-radius: 100vmax;
        transform: translateY(-50%);
        &--prev {
          left: -70px;
        }
        &--next {
          right: -70px;
        }
        img {
          width: 12px;
        }
      }
    }
  }
  .link {
    display: flex;
    justify-content: flex-end;
    width: 1000px;
    margin: 18px auto 0;
    @include sp(&) {
      display: none;
    }
    a {
      display: flex;
      gap: 15px;
      align-items: center;
      img {
        width: 40px;
      }
      .txt {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}

.block-top-topiclist {
  background-image: url('/wwwroot/design/img/common/bg_2.png');
  @include pc(&) {
    padding: 80px 0;
  }
  @include sp(&) {
    padding: 55px 20px;
  }
  &-inr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include pc(&) {
      width: 1080px;
    }
    @include sp(&) {
      flex-direction: column;
      gap: 30px;
      width: 100%;
    }
  }
  &-head {
    h2 {
      @include f-KaiseiOpti;
      line-height: 1;
      @include pc(&) {
        font-size: 34px;
      }
      @include sp(&) {
        font-size: 30px;
        text-align: center;
      }
    }
    .sub {
      @include f-YesevaOne;
      margin-top: 10px;
      line-height: 1;
      color: $c-green;
      @include pc(&) {
        font-size: 20px;
      }
      @include sp(&) {
        font-size: 16px;
        text-align: center;
      }
    }
  }
  &-body {
    background: $c-white;
    border-radius: 14px;
    @include pc(&) {
      width: 880px;
      height: 350px;
      padding: 30px;
    }
    @include sp(&) {
      position: relative;
      width: 100%;
      padding: 15px;
      overflow: hidden;
    }
    .topicline {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-right: 20px;
      .simplebar-scrollbar::before {
        width: 3px;
        background-color: $c-green;
        opacity: 1;
      }
      li {
        list-style: none;
        border-bottom: 1px solid $c-green3;
        @include pc(&) {
          padding: 15px 0 20px;
        }
        @include sp(&) {
          padding: 20px 0;
        }
        &:first-child {
          @include pc(&) {
            padding-top: 0;
          }
          @include sp(&) {
            padding-top: 30px;
          }
        }
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
        .head {
          display: flex;
          gap: 16px;
          .date {
            font-size: 16px;
            font-weight: bold;
          }
          .label {
            img {
              height: 22px;
            }
          }
        }
        .cont {
          margin-top: 15px;
          a {
            @include pc(&) {
              font-size: 16px;

              &:hover {
                color: $c-green;
              }
            }
            @include sp(&) {
              font-size: 14px;
            }
          }
        }
      }
      // accordion
      @include sp(&) {
        height: 450px;
        padding: 0;
        overflow: hidden;
        transition: all ease 0.3s;
        .more {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100px;
          font-size: 18px;
          font-weight: bold;
          background: rgb(245, 244, 242);
          background: linear-gradient(0deg, rgba(245, 244, 242, 1) 0%, rgba(245, 244, 242, 0.8925945378151261) 70%, rgba(9, 9, 121, 0) 100%);
          &::before {
            position: relative;
            width: 14px;
            height: 8px;
            content: '';
            background-image: url('/wwwroot/design/img/common/arrow_down_green.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
        &.is-open {
          height: auto;
          padding-bottom: 100px;
          .more {
            &:before {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

.event_ {
  position: relative;
  width: 100%;
  padding: 34px 0 64px;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    background: $c-green4;
    mix-blend-mode: soft-light;
  }
  &inr_ {
    position: relative;
    z-index: 2;
  }
  .free_event_title_ {
    padding: 0;
    margin: 40px auto;
    text-align: center;
    background: none;
    @include pc(&) {
      width: 1080px;
    }
    @include sp(&) {
      width: 100%;
    }
    &:nth-child(n + 2) {
      margin-top: 80px;
    }
    h2 {
      @include f-KaiseiOpti;
      padding: 0;
      font-size: 34px;
      font-weight: bold;
      text-indent: 0;
      background: none;
    }
    .sub {
      @include f-YesevaOne;
      font-size: 20px;
      color: $c-green;
    }
  }
}

.goods_ {
  z-index: 1;
  padding-top: 20px;
  margin: 0 auto;
  @include pc(&) {
    width: 1080px !important;
    overflow: hidden;
  }
  @include sp(&) {
    width: 100%;
    padding-bottom: 30px;
    .simplebar-scrollbar::before {
      height: 2px;
      background-color: $c-green;
      opacity: 1;
    }
  }
  .StyleT_Frame_,
  .StyleR_Frame_ {
    margin: 0;
    @include pc(&) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 20px;
      overflow: visible;
    }
    @include sp(&) {
      display: flex;
      gap: 20px;
      width: max-content;
      padding: 0 20px;
    }
    .StyleT_Item_,
    .StyleR_Item_ {
      position: relative;
      margin: 0;
      @include pc(&) {
        width: auto;
      }
      @include sp(&) {
        width: 150px;
      }
      .rank_ {
        @include f-QuincyCF;
        position: absolute;
        top: -10px;
        left: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 46px;
        font-size: 24px;
        line-height: 1;
        background-image: url('/wwwroot/design/img/common/ranking_bg.png');
        background-repeat: no-repeat;
        background-size: contain;
      }
      .img_ {
        position: relative;
        height: auto;
        aspect-ratio: 1/1;
        overflow: visible;
        background: none;
        @include pc(&) {
          width: 100%;
        }
        @include sp(&) {
          width: 150px;
        }
        a.goods_name_ {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          aspect-ratio: 1 / 1;
          img {
            width: 100%;
            max-width: none;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
          }
        }
      }
      .desc_ {
        min-height: auto !important;
        margin-bottom: 0;
        @include sp(&) {
          margin-top: 10px;
        }
        .name_ {
          font-weight: bold;
          @include pc(&) {
            font-size: 14px;
          }
          @include sp(&) {
            font-size: 13px;
          }
          a {
            &:hover {
              color: $c-green;
              text-decoration: underline;
            }
          }
        }
        .box1 {
          display: flex;
          flex-direction: column;
          gap: 15px;
          margin-top: 10px;
        }
        .color_ {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          justify-content: center;
          img {
            width: 18px;
            height: 18px;
            border-radius: 100vmax;
          }
        }
      }
      .review {
        margin-top: 15px;
      }
      .price_ {
        margin: 15px 0 0;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        @include sp(&) {
          color: $c-black;
        }
        span {
          @include pc(&) {
            font-size: 20px;
          }
          @include sp(&) {
            font-size: 18px;
          }
        }
        &.is-sale {
          font-weight: normal;
          .sale_price_ {
            font-weight: bold;
            color: $c-orange2;
            span {
              @include pc(&) {
                font-size: 20px;
              }
              @include sp(&) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.block-top-hotKeyword {
  background-image: url('/wwwroot/design/img/common/bg_2.png');
  @include pc(&) {
    padding: 80px 0;
  }
  @include sp(&) {
    padding: 60px 0 70px;
  }
  &-inr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    @include pc(&) {
      width: 1080px;
    }
    @include sp(&) {
      flex-direction: column;
      gap: 30px;
      width: 100%;
    }
  }
  &-head {
    h2 {
      @include f-KaiseiOpti;
      line-height: 1;
      @include pc(&) {
        font-size: 34px;
      }
      @include sp(&) {
        font-size: 30px;
        text-align: center;
      }
    }
    .sub {
      @include f-YesevaOne;
      margin-top: 10px;
      line-height: 1;
      color: $c-green;
      @include pc(&) {
        font-size: 20px;
      }
      @include sp(&) {
        font-size: 16px;
        text-align: center;
      }
    }
  }
  &-body {
    @include pc(&) {
      width: 880px;
    }
    @include sp(&) {
      width: 100%;
      padding: 0 20px;
    }
    .keywords {
      display: flex;
      flex-wrap: wrap;
      @include pc(&) {
        gap: 20px;
      }
      @include sp(&) {
        gap: 10px;
        justify-content: center;
      }
      a.keyword {
        line-height: 1;
        background: $c-white;
        border: 1px solid $c-green;
        border-radius: 100vmax;
        @include pc(&) {
          padding: 12px 30px;
          font-size: 16px;
          font-weight: bold;
        }
        @include sp(&) {
          padding: 10px;
          font-size: 13px;
        }
        &:hover {
          color: $c-white;
          background: $c-green;
          opacity: 1;
        }
      }
    }
  }
}

.block-top-feature {
  &-inr {
    @include sp(&) {
      margin-top: 70px;
    }
  }
  &-slider {
    width: 1000px;
    margin: 50px auto 0;
    @include sp(&) {
      width: 100%;
      visibility: visible;
    }
    .splide__track {
      @include sp(&) {
        padding-bottom: 30px;
        overflow-x: scroll;
      }
      .simplebar-scrollbar::before {
        height: 2px;
        background-color: $c-green;
        opacity: 1;
      }
    }
    .splide__list {
      @include sp(&) {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-auto-flow: column;
        gap: 25px 15px;
        width: max-content;
        padding: 0 20px !important;
      }
    }
    .splide__slide {
      display: flex;
      flex-direction: column;
      @include sp(&) {
        width: 200px;
      }
      a {
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
          @include pc(&) {
          }
          @include sp(&) {
            width: 200px;
            height: 180px;
          }
        }
        .sub {
          line-height: 1;
          word-break: keep-all;
          overflow-wrap: break-word;
          @include pc(&) {
            margin-top: 16px;
            font-size: 12px;
          }
          @include sp(&) {
            margin-top: 15px;
            font-size: 12px;
          }
        }
        .ttl {
          margin-top: 10px;
          font-weight: bold;
          line-height: 1;
          word-break: keep-all;
          overflow-wrap: break-word;
          @include pc(&) {
            font-size: 18px;
          }
          @include sp(&) {
            font-size: 16px;
          }
        }
        &:hover {
          .sub,
          .ttl {
            color: $c-green;
          }
        }
      }
    }
    .splide__arrows {
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: space-between;
      width: 1000px;
      transform: translate(-50%, -50%);
      .splide__arrow {
        position: absolute;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background: $c-green;
        border-radius: 100vmax;
        transform: translateY(-50%);
        &--prev {
          left: -70px;
        }
        &--next {
          right: -70px;
        }
        img {
          width: 12px;
        }
      }
    }
  }
  &-more {
    margin-bottom: 80px;
  }
}

.block-top-search {
  padding: 0 0 80px;
  &-inr {
  }
  @include sp(&) {
    &-main {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      padding: 0 20px;
      .cont {
        overflow: hidden;
        background: $c-white;
        border-radius: 14px;
        .ttl {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          font-size: 16px;
          font-weight: bold;
          background: $c-green3;
        }
        .main {
          padding: 30px 25px 20px;
          .track {
            width: 100%;
            padding-bottom: 30px;
            overflow-x: scroll;
            overflow-y: hidden;
            .simplebar-scrollbar::before {
              height: 2px;
              background-color: $c-green;
              opacity: 1;
            }
          }
        }
      }
      .cont-1,
      .cont-4,
      .cont-5 {
        .main {
          .track {
            .list {
              display: grid;
              grid-template-rows: repeat(10, 1fr);
              grid-template-columns: repeat(4, 1fr);
              grid-auto-flow: column;
              gap: 10px 20px;
              a {
                display: flex;
                align-items: center;
                width: 200px;
                font-size: 14px;
                font-weight: bold;
                line-height: 1;
                &::before {
                  position: relative;
                  display: inline-block;
                  width: 4px;
                  height: 100%;
                  margin-right: 15px;
                  content: '';
                  background-image: url('/wwwroot/design/img/common/arrow_next_green.png');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
            }
          }
        }
      }
      .cont-2 {
        .main {
          padding-bottom: 0;
          .track {
            .list {
              display: grid;
              grid-template-rows: repeat(2, 1fr);
              grid-auto-flow: column;
              gap: 30px 10px;
              a {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;
                width: 100px;
                .img {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  aspect-ratio: 1/1;
                  border: 1px solid $c-green3;
                  border-radius: 100vmax;
                  img {
                    width: 80%;
                  }
                }
              }
            }
          }
        }
      }
      .cont-3 {
        .main {
          .track {
            overflow-x: scroll;
            overflow-y: hidden;
            .keywords {
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              width: 600px;
              a.keyword {
                padding: 11px 10px;
                font-size: 13px;
                line-height: 1;
                background: $c-white;
                border: 1px solid $c-green;
                border-radius: 100vmax;
              }
            }
          }
        }
      }
      .cont-5 {
        .main .track .list a {
          width: 160px;
        }
      }
    }
    &-more {
      margin-bottom: 80px;
    }
  }
}

.block-top-revico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 1px solid #000;
}

.block-top-visumo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border: 1px solid #000;
}

.block-top-about {
  width: 100%;
  @include pc(&) {
    padding: 80px 0;
  }
  @include sp(&) {
    padding: 50px 20px;
  }

  &-inr {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    background: $c-green3;
    background-image: url('/wwwroot/design/img/common/bg_3.png');
    background-position: center;
    background-size: cover;
    @include pc(&) {
      width: 1080px;
      padding: 65px 90px;
      border-top-right-radius: 100px;
      border-bottom-left-radius: 100px;
    }
    @include sp(&) {
      width: 100%;
      padding: 40px 15px 128px;
      border-top-right-radius: 80px;
      border-bottom-left-radius: 80px;
    }
  }
  &-main {
    .ttl {
      @include f-YesevaOne;
      line-height: 1;
      color: $c-green;
      text-align: center;
      @include pc(&) {
        font-size: 60px;
      }
      @include sp(&) {
        font-size: 36px;
      }
    }
    .sub {
      @include f-KaiseiOpti;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      @include pc(&) {
        margin-top: 10px;
        font-size: 18px;
      }
      @include sp(&) {
        margin-top: 7px;
        font-size: 16px;
      }
    }
    .main {
      @include pc(&) {
        margin-top: 35px;
      }
      @include sp(&) {
        margin-top: 45px;
      }
      p {
        @include f-ZenKakuGothicNew;
        margin-top: 30px;
        line-height: 1.7;
        text-align: center;
        @include pc(&) {
          font-size: 16px;
        }
        @include sp(&) {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .more {
      text-align: center;
      @include pc(&) {
        margin-top: 40px;
      }
      @include sp(&) {
        margin-top: 37px;
      }
      a {
        display: inline-flex;
        gap: 15px;
        align-items: center;
        img {
          width: 40px;
        }
        .txt {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .visual {
      position: absolute;
      @include pc(&) {
        right: 60px;
        bottom: 30px;
      }
      @include sp(&) {
        right: 20px;
        bottom: 30px;
      }
      img {
        @include pc(&) {
          width: 100px;
        }
        @include sp(&) {
          width: 90px;
        }
      }
    }
  }
}

.block-top-recently {
  @include pc(&) {
    margin: 20px 0 140px;
  }
  @include sp(&) {
    margin: 20px 0 130px;
  }
  &-inr {
    margin: 0 auto;
    @include pc(&) {
      width: 1080px;
    }
    @include sp(&) {
      width: 100%;
    }
    .ttl {
      h2 {
        @include f-KaiseiOpti;
        @include pc(&) {
          font-size: 28px;
        }
        @include sp(&) {
          padding: 0 20px;
          font-size: 24px;
        }
      }
    }
    .goods_ {
      @include pc(&) {
        margin-top: 45px;
      }
      @include sp(&) {
        margin-top: 24px;
      }
    }
    .misc {
      margin-top: 30px;
      text-align: right;
      @include sp(&) {
        padding-right: 20px;
      }
      a {
        font-size: 16px;
        font-weight: bold;
        color: $c-green;
        text-decoration: underline;
      }
    }
  }
}
