.u-pc {
  @include sp(&) {
    display: none !important;
  }
}

.u-sp {
  @include pc(&) {
    display: none !important;
  }
}

// form
.u-input,
.u-button,
.u-select,
.u-textarea {
  font: inherit;
  background: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

.u-button,
.u-select,
.u-textarea {
  cursor: pointer;
}
