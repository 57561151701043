// css for override ecb default style
.review {
  height: 35px;
  .revico-star {
    .revico-star-block--rate {
      top: 2px;
      margin: 0;
      @include pc(&) {
        transform: scale(0.5);
      }
      @include sp(&) {
        transform: scale(0.45);
      }
    }
    .revico-star-block--title {
      @include pc(&) {
        margin-left: -70px;
        font-size: 13px;
      }
      @include sp(&) {
        margin-left: -77px;
        font-size: 12px;
      }
    }
    .revico-star-block--total {
      margin: 0;
      @include pc(&) {
        font-size: 11px;
      }
      @include sp(&) {
        margin-top: 1px;
        font-size: 10px;
      }
    }
  }
}

.m_info {
  overflow: hidden;
  img {
    display: inline;
    float: left;
    margin: 0 10px 10px 0;
  }

  p.bottan {
    a {
      overflow: hidden;
    }
    img {
      float: right;
      margin: 0;
      text-align: right;
    }
  }
}

.div.goodscomment_ {
  .goodscomment_head {
    padding: 0;
    background: none;
    border: none;
  }
}
.goodscomment_head {
  padding: 0;
  background: none;
  border: none;
}

table.keepcart_ {
  td {
    vertical-align: bottom;
  }
}

.block-staff {
  @include pc(&) {
    width: 1000px;
    margin: 0 auto;
  }
  @include sp(&) {
    padding: 0 20px;
  }
}

.rightmenuframe_ {
  display: none;
}

.freepage_ {
  @include sp(&) {
    margin-top: 20px;
  }
}
h1.category_name_ {
  background: none;
}
h1.event_name_ {
  background: none;
  @include f-KaiseiOpti;
  @include pc(&) {
    min-height: 0;
    padding: 0;
    margin: 0;
    font-size: 28px;
    font-weight: normal;
    line-height: normal;
    color: $c-black;
  }
  @include sp(&) {
    padding: 0 20px;
    font-size: 22px;
  }
}
.shop_detail_ {
  @include sp(&) {
    padding: 0 20px;
  }
}

#company.guidepage_ {
  @include sp(&) {
    h1.common_headline1_ {
      padding: 0 20px;
    }
    .style15_ {
      padding: 0 20px;
      .box_ {
        padding: 0;
      }
    }
  }
}

.goodscomment6_ {
  margin-top: 50px;
  font-size: 16px;
  line-height: 1.6;
}

.order_ {
  h1.common_headline1_ {
    @include sp(&) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  h2.common_headline2_ {
    @include sp(&) {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
  .method_host_ {
    h2.common_headline2_ {
      @include sp(&) {
        margin-top: 0;
      }
    }
  }
}

.customer_ {
  h1.common_headline1_ {
    @include sp(&) {
      margin-left: 10px;
    }
  }
  h2 {
    @include sp(&) {
      margin-left: 10px;
    }
  }
}

.page-list {
  .goods_ {
    @include sp(&) {
      padding: 0 20px;
    }
  }
}

input[name='min_price'],
input[name='max_price'] {
  @include sp(&) {
    width: 90%;
  }
}

div.bookmark_item_ {
  div.cartgoods_ {
    @include pc(&) {
      padding: 0;
      margin-right: 10px;
    }
  }
  div.del_ {
    @include pc(&) {
      padding: 0;
    }
  }
}
